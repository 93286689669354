import React from 'react';

import {
  Container,
} from '@mui/material';

import Page from '../components/Page';
import SlotCalendar from 'src/components/SlotCalendar';

export default function AvailabilityPage(props) {
    return (
    <Page title="Flyspace | Availability Calendar">
            <Container>
                <SlotCalendar />
            </Container>
    </Page>
  )
}




  
  