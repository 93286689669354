import React, { useContext, useState, useEffect } from 'react';

import {
  Card,
  Table,
  TableHead,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  IconButton,
  Tooltip,
} from '@mui/material';

import { AuthContext } from '../Auth';
import { ReloaderContext } from '../Reloader';

import moment from "moment";

// material
// components
import Scrollbar from '../components/Scrollbar';
import SyllabusPanel from '../components/_dashboard/syllabus/SyllabusPanel';
import { sDiffToHours } from '../utils/formatTime';
import { DeleteForever, DeleteForeverOutlined, DeleteOutline } from '@mui/icons-material';


function serializeObject(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'aircraft', label: 'Aircraft', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'from', label: 'From', alignRight: false },
    { id: 'to', label: 'To', alignRight: false },
    { id: 'start', label: 'Block start', alignRight: false },
    { id: 'end', label: 'Block end', alignRight: false },
    { id: 'warmup', label: 'Warmup', alignRight: false },
    { id: 'flight_time', label: 'Block', alignRight: false },
    { id: 'landings', label: 'Landings', alignRight: false },
    { id: 'exercise', label: 'Exercise', alignRight: false },
    { id: 'instructor', label: 'Instructor', alignRight: false },
    { id: 'pilot', label: 'Pilot', alignRight: false },
    { id: 'lessee', label: 'Lessee', alignRight: false }
];



export default function Logbook({id, editMode, filterType, filter, tid}) {
    const { getUrl,user } = useContext(AuthContext);
    const { refresher } = useContext(ReloaderContext);

    const [state, setState] = useState({logbook: null});
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(null);
  
    let filterValues = (filter) ? serializeObject(filter) : "";
    let tidFilter = (tid) ?  "&tid=" + tid : "";
    
    const fetchLogbook = async () => { 
        setLoading(true);

        const url = getUrl('index','logbook') + "&filter="+((!filterType) ? "all" : filterType)+"&id="+ id + "&per_page=25&page=" + (page+1) + "&" + filterValues + tidFilter;
        const result = await fetch(url).then((res) => res.json()).catch(err => {});
        setState({
            logbook:result?.value ?? []
        });
        setLoading(false);
    }

    useEffect(() => {
        fetchLogbook();
    }, [page, refresher, filterType,id, filter]);
    
    let edit = (user.admin === 1) ? true : false;

    if(editMode !== undefined && editMode === false)
        edit = false;

    const renderTableCell = (align, onClick, value, tooltip = null) => (
        <TableCell align={align} onClick={onClick} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:"130px" }}>
            <Tooltip title={<>
                <Typography variant='body2'>
                {value}
                </Typography>
                {
                    tooltip &&  <Typography variant='body2'>{tooltip}</Typography>
                }
                </>}>
                {value}
            </Tooltip>
        </TableCell>
    );

    const renderActionsCell = (id) => {
        const handleDelete = async (id) => {
            if(!window.confirm("Are you sure you want to delete this log?"))
                return;
            
            setLoading(true);
            const result = await fetch(getUrl('delete','logbook') + "&id=" + id, {method: "POST"}).then((res) => res.json()).catch(err => {});
            setLoading(false);

            fetchLogbook();
        }

        return (
            <TableCell align="left">
                <IconButton onClick={() => handleDelete(id)}>
                    <DeleteOutline/>
                </IconButton>
            </TableCell>
        );
    }

    const tableHeader = user.admin === 1 ? [...TABLE_HEAD, ...[{ id: 'actions', label: 'Actions', alignRight: false }]] : [...TABLE_HEAD];
        
    return (
              <Card>
                    <TableContainer sx={{ width: '100%'}}>
                    <Table>
                            <TableHead>
                                        <TableRow>
                                            {tableHeader.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                            >
                                                {headCell.label}
                                            </TableCell>
                                            ))
                                            }
                                        </TableRow>
                            </TableHead>
                        <TableBody>
                        {
                          loading == false && state?.logbook?.logbook?.map((log, index) => (
                            <TableRow hover key={log.id} tabIndex={-1} role="checkbox" aria-checked={false}>
                              {renderTableCell("left", () => {}, log.id, log?.paid_flight == 1 ? "paid flight" : "")}
                              {renderTableCell("left", () => {}, log.aircraft.identification)}
                              {renderTableCell("left", () => {}, moment(log.start*1000).utc().format("DD/MM/YY"))}
                              {renderTableCell("left", () => {}, log.from.icao)}
                              {renderTableCell("left", () => {}, log.to.icao)}
                              {renderTableCell("left", () => {}, moment(log.start*1000).utc().format("HH:mm"))}
                              {renderTableCell("left", () => {}, moment(log.end*1000).utc().format("HH:mm"))}
                              {renderTableCell("left", () => {}, log.warmup + "M")}
                              {renderTableCell("left", () => {}, sDiffToHours(log.start,log.end))}
                              {renderTableCell("left", () => {}, log.landings)}
                              {renderTableCell("left", () => {}, (log?.exercise ? log.exercise.ident + " - " + log.exercise.name : "-" ))}
                              {renderTableCell("left", () => {}, log.instructor && log.instructor.name)}
                              {renderTableCell("left", () => {}, log.pic && log.pic.name)}
                              {renderTableCell("left", () => {}, log.lessee && log.lessee.name)}
                              { user.admin === 1 && renderActionsCell(log.id)}
                            </TableRow>
                          ))
                        }
                        {
                            loading && 
                                <TableRow>
                                    <TableCell colSpan={15}>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    </TableCell>
                                </TableRow>
                        }
                        {
                            loading === false && state?.logbook?.logbook?.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={15}>
                                    <Typography align="center">
                                        No logs found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={state.logbook ? state.logbook.total : 0}
                      rowsPerPage={25}
                      page={page}
                      onPageChange={(el,val) => { 
                            setPage(val) 
                        }
                      }
                      onRowsPerPageChange={() => {}}
                  />
              </Card>
    )
   
  
   
  }
  

