import PropTypes from 'prop-types';
import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { CircularProgress, Container, Typography } from '@mui/material';

import Logo from '../components/Logo';
import { MHidden } from '../components/@material-extend';

export default function PreloaderLayout({ children }) {

  return (
    <>
    <CircularProgress style={{'margin': '50%'}}/>
    <Container style={{display:"none"}}>
      <Outlet/>
    </Container>
    </>
  );
}
