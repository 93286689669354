import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Card, Stack, Table, TableHead, TableBody, TableRow, TableCell, Select, MenuItem, Tooltip, Avatar, Typography, CircularProgress, Button, Menu, ListItemText, useTheme, Box, FormControl, Autocomplete, TextField, FormControlLabel } from '@mui/material';
import moment from 'moment';
import { WeekPicker } from 'src/components/WeekPicker';
import Scrollbar from './Scrollbar';
import { AuthContext } from '../Auth';
import AppDrawer from './AppDrawer';
import { LoadingButton } from '@mui/lab';

const DEFAULT_HEADER = ["5AM", "6AM", "7AM", "8AM","9AM","10AM","11AM","12PM","13PM","14PM","15PM","16PM","17PM","18PM","19PM"];


const SlotCalendarStyles = (theme) => { 
    const base = {
        borderRadius: 1,
        width: 40,
        height: 40,
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
    }
    const styles =  {
        highlightNone: {
            ...base,
            backgroundColor: theme.palette.grey[400],
        },
        highlightToday: {
            ...base,
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        highlightPast: {
            ...base,
            opacity:0.8,
            fontWeight: 'bold',
            cursor:'not-allowed !important'
        },
        highlightAvailable: {
            ...base,
            backgroundColor: theme.palette.primary.main,
        },
        highlightUnavailable: {
            ...base,
            cursor:'pointer',
            backgroundColor: theme.palette.grey[300],
        },
        highlightBooked: {
            ...base,
            backgroundColor: theme.palette.warning.light,
            cursor:'not-allowed !important'
        },
        highlightClosed: {
            ...base,
            backgroundColor: theme.palette.grey[500],
            cursor:'not-allowed !important'
        },
        multipleSlots: {
            ...base,
            backgroundColor: theme.palette.primary.main,
            position:'absolute',
            top:"-20px"
        }
    }

    return styles;
};


const getWeekByDate = (date) => {
    const week = {
        year: date.clone().isoWeekYear(),
        week: date.clone().isoWeek(),
        start : date.clone().startOf('isoWeek'),
        end: date.clone().endOf('isoWeek')
    };
    
    return week;
}

const getKeyFromMoment = (obj, excludeDay = false) =>{
    return excludeDay ? obj.isoWeekYear() + "_" + obj.isoWeek() + "_" + obj.day() : 
        obj.isoWeekYear() + "_" + obj.isoWeek() + "_" + obj.day() + "_" + obj.hour();
}


export default function SlotCalendar(){
    const theme = useTheme();
    const styles = SlotCalendarStyles(theme);

    const { isLoggedIn, getUrl, user } = useContext(AuthContext);
    
    const [slots, setSlots] = useState({});
    const [loading, setLoading] = useState(false);
    
    //selected member
    const [selectedMember, setSelectedMember] = useState(null);

    const [week, setWeek] = useState(getWeekByDate(moment()));

    const fetchAvailability = async () => {
        setLoading(true);
        const res = await fetch(getUrl("", 'availability') + "&aid=0&&week=" + week.week + "&year=" + week.year).catch((error) => {});
        const result = (await res.json())?.value ?? {};

        setLoading(false);
        setSlots({...result});
    }

    useEffect(() => {
        fetchAvailability();
    }, [week]);

    //get the current week days
    let header = DEFAULT_HEADER;
    if(slots != null && Object.values(slots).length > 0)
       header = Object.keys(Object.values(slots)?.[0]) ?? DEFAULT_HEADER
    

    //get the uniwue members from all the slots
    const uniqueMembers = [];

    Object.keys(slots).forEach(day => {
        Object.keys(slots[day]).forEach(hour => {
            if(slots[day][hour] === null)
                return;
            
            slots[day][hour].forEach(m=>{
                if(m.id != null && uniqueMembers.filter(e => e.id === m.id).length === 0)
                    uniqueMembers.push(m);
            });
        }); 
    });


    const admin = user.admin === 1;
    
    return (
        <>
        <Stack mb={5} direction="row" flexWrap="wrap">
            <WeekPicker onChange={(date) => { setWeek(getWeekByDate(date)); }} />
            {
                admin && 
                    <Stack direction="row" margin={1} flexWrap="wrap">
                        {
                            uniqueMembers.map(el => {
                                const opacity = selectedMember && el?.id == selectedMember?.id ? 1 : 0.4

                                return (
                                    <Tooltip
                                        title={el.name}
                                    >
                                        <Avatar 
                                            alt={el.name} 
                                            src={el.picture} 
                                            style={{borderRadius: "10px", opacity: opacity, cursor:'pointer', marginLeft: 10}} 
                                            onClick={() => {
                                                setSelectedMember(selectedMember !== null && el.id === selectedMember.id ? null : el);
                                            }}
                                        />
                                    </Tooltip>
                                )
                            })
                        }
                    </Stack>
                }
        </Stack>
        <Card sx={{overflowX: 'auto'}}>
            { loading && <CircularProgress style={{top:'50%', left:'50%', position:'absolute', zIndex: '1000'}}/> }
            <Table style={{opacity: loading ? 0.5 : 1, minHeight:"600px"}}>
            <TableHead>
                <TableRow>
                    <TableCell style={{position: 'sticky', left: 0, background:theme.palette.grey[100], zIndex:10000}}></TableCell>
                    {
                        header.map(el => {return ( <TableCell align={"center"} key={el}>{el}</TableCell>)})
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(slots).map(day => {
                        const today = moment().startOf('day');
                        const dayObj = moment(day, 'DD/MM/YY');
                        
                        if(day == "15/02/24")
                            console.log(slots[day])

                        const isToday = dayObj.isSame(today, "date");
                        const isPast = dayObj < today;
                        const hours = slots.hasOwnProperty(day) ? Object.getOwnPropertyNames(slots[day]) : [];

                        return (
                                <TableRow key={"row_" + day}>
                                <TableCell style={{position: 'sticky', left: 0, background:"white", zIndex:10000}}>
                                    <Stack direction={"row"}>
                                        <Typography variant='body2' sx={{opacity: isPast ? 0.2 : 1, fontWeight: isToday ?  "bold" : "inherit"}}>{dayObj.format("ddd") }</Typography>
                                    </Stack>
                                </TableCell>
                                {
                                    hours.map(slot => {
                                        let slotTime = dayObj.clone();
                                        slotTime.set({hour:parseInt(slot.slice(0,2)),minute:0,second:0,millisecond:0});
                                        let members = slots?.[day]?.[slot] ?? [];
                                        
                                        
                                        return (
                                        <TableCell key={getKeyFromMoment(slotTime)} align={'center'}>
                                            { 
                                                isPast && <Box sx={styles.highlightPast}></Box>
                                            }
                                            {
                                                !isPast && 
                                                    <BookingSlot 
                                                        key={"slot_" + getKeyFromMoment(slotTime)} 
                                                        slotTime={slotTime} 
                                                        slotMembers={members} 
                                                        selectedMember={selectedMember} 
                                                        onClose={async () => {
                                                            await fetchAvailability();
                                                        }}></BookingSlot>
                                            }

                                            
                                        </TableCell>)
                                    })
                                    
                                }
                            </TableRow>
                            
                        )
                    })
                }
                </TableBody>
            </Table>
        </Card>
        </>
    )
}



const BookingSlot = ({slotTime, slotMembers, selectedMember, onClose}) => {
    const styles = SlotCalendarStyles(useTheme());
    const { getUrl, user } = useContext(AuthContext);
    

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [values, setValues] = useState({
        members: []
    });

    const [state, setState] = useState({
        pic: null
    })

    const addToCalendar = async () => {
        if(state.pic == null)
            return false;

        if(!window.confirm("Are you sure you want to make this flight apppointment?"))
            return;
        
        const formData  = new FormData();
        const key = getKeyFromMoment(slotTime);
        
        formData.append("slot", key);
        formData.append("uid", state.pic);
        formData.append("type", "FLIGHT");
        
        setLoading(true);
        
        await fetch(getUrl('events', "availability"), {
            method: 'post',
            body: formData
        }).finally(() => {
        });

        setLoading(false);
        setOpen(false);
        onClose();
    };

    const removeFromCalendar = async (gid) => {
        if(gid == null)
            return;

        const formData  = new FormData();
        
        if(!window.confirm("This is a scheduled flight. If you remove it, you will release the slot. Are you sure you want to continue ?"))
            return;

        formData.append("gid", gid);
        setLoading(true);

        await fetch(getUrl('cancel', 'availability'), {
            method: 'post',
            body: formData
        }).catch((error) => {});

        onClose();
    }

    const saveAvailability = async (slotMember, slot) => {
        const formData  = new FormData();
        const key = getKeyFromMoment(slot);
        
        formData.append("slot", key);
        formData.append("aircraft", 0);
        formData.append("type", slotMember?.type ?? "AVAILABILITY");
        setLoading(true);
        
        await fetch(getUrl("", 'availability'), {
            method: 'post',
            body: formData
        }).catch((error) => {});

        onClose();
    }


    useEffect(() => {
        const _filteredMembers = selectedMember != null ? slotMembers.filter(m => m.id === selectedMember.id) : slotMembers;
        setValues({...values, members: _filteredMembers});

    }, [open, selectedMember, slotMembers]);

    const toolTipTitle = values?.members?.map(m => { return <div>{m.name}</div> }) ?? "";
    const appointmenId = values?.members?.find(m => m.gid != null)?.gid ?? null;
    
    return (
        <>
            <Box onClick={() => {
                if(user.admin == 1)
                { 
                    !appointmenId && setOpen(true); 
                    appointmenId && removeFromCalendar(appointmenId)
                }else{
                    appointmenId && removeFromCalendar(appointmenId)
                    !appointmenId && saveAvailability(selectedMember, slotTime);
                }
            }}>
                <Stack style={{position:'relative'}}>
                    {
                        values?.members?.length > 0 && values.members.sort((a, b) => (b.gid != null) - (a.gid != null)).map((m,index) => {
                            const opacity = (1-(0.4) * index);
                            const margin = index*5;
                            const zIndex = m?.gid != null ? 1000 : 1;
                            return (
                                <Tooltip title={toolTipTitle} key={"slot_member_" + getKeyFromMoment(slotTime) + "_" + m.id}>
                                    <Box sx={{...styles.multipleSlots, ...styles.highlightAvailable}} style={{margin:margin, opacity:opacity, zIndex:zIndex}}>
                                        { m?.gid && <Avatar alt={m?.name} src={m?.picture} style={{borderRadius: "10px"}} /> }
                                    </Box>
                                </Tooltip>
                            )
                        })
                    }
                    {
                        values?.members?.length === 0 && <Box sx={styles.highlightUnavailable}></Box>
                    }
                </Stack>
            </Box>
           
            <AppDrawer loading={loading} height={50} title={"Book a flight for " + moment(slotTime).format("HH:mm A, dddd")} open={open} onClose={() => setOpen(false)}>
                {
                    open && 
                        <Stack direction="column" spacing={2}>
                            <FormControl>
                                <MembersAutocomplete 
                                    selected={selectedMember ?? slotMembers[0]} 
                                    onChange={(id) => {
                                        if(id != null)
                                            setState({pic: id});
                                    }} 
                                />
                            </FormControl>
                        <FormControl>
                                <LoadingButton loading={loading} size='large' onClick={() => {
                                    addToCalendar(state.pic);
                                }} variant="contained" color="primary">Make appointment</LoadingButton>
                            </FormControl>
                    </Stack>
                }   
            </AppDrawer> 
            
        </>  
    )
}


const MembersAutocomplete = ({selected, onChange}) => {
    const { getUrl } = useContext(AuthContext);
    
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedMember, setSelectedMember] = useState(null);

    const fetchMembers = async () => {
        setLoading(true);
        const res = await fetch(getUrl('pilots') + "&roles[]=instructor&&roles[]=student&roles[]=pilot").catch((error) => {});
        const result = await res.json().catch((error) => {});
        
        const resMembers = result?.value.map(m => {
            return {id: m.id, label: m.name}
        }) ?? [];
        
        setMembers(resMembers);

        if(selected?.id){
            const _selected = resMembers.find(m => m.id == selected?.id);
            setSelectedMember(_selected);
            onChange(_selected?.id ?? null);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchMembers();
    }, []);

    return (
        <Autocomplete
            loading={loading}
            sx={{minWidth:300}}
            id="pilots"
            value={selectedMember}
            options={members}
            onChange={(event, newValue) => {
                setSelectedMember(newValue)
                onChange(newValue?.id ?? null);
            }}
            renderInput={(params) => <TextField {...params} />}
        />
    )
}
