import React, { useContext, useState } from 'react';

import {
  Stack,
  Container,
  Typography,
  Link,
  Breadcrumbs,
} from '@mui/material';
import { AuthContext } from '../../Auth';

import Page from '../../components/Page';
import Documents from 'src/components/Documents';
import { useParams } from 'react-router-dom';

export default function AdminDocumentsPage(props) {
  const { user } = useContext(AuthContext);
  let { id } = useParams();
  
  return (
    <Page title="Flyspace | Admin documents">
      <Container>
        <Stack direction="row">
          <Breadcrumbs aria-label="breadcrumb" mb={5}>
            <Link color="inherit" href="/" >
              Students Report
            </Link>
            <Typography color="textPrimary">Documents</Typography>
          </Breadcrumbs>
        </Stack>
       
      </Container>

      <Documents id={id} editMode={user.admin === 1}/>
    </Page>
  );
}
