import React, { useState, useEffect, useContext } from 'react';


export const ReloaderContext = React.createContext(null);

const generateUnique = () => {
    const min = 1;
    const max = 1000000;
    const rand = min + Math.random() * (max - min);
    
    return rand;
  }

export default function ReloaderContextProvider({ children }) {
  const [refresher, setRefresher] = useState(generateUnique());

  return (
    <ReloaderContext.Provider
      value={{
        refresher: refresher,
        onReload: () => {
          setRefresher(generateUnique());
        }
      }}
    >
      {children}
    </ReloaderContext.Provider>
  );

 
}
