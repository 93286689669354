import React, { useContext, useState, useEffect } from 'react';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, useFormikContext } from 'formik';

import * as yup from 'yup';

import _uniqueId from 'lodash/uniqueId';


import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableHead,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  Link,
  Breadcrumbs,
  Icon,
  IconButton,
  CardHeader,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  FormLabel,
  Select,
  MenuItem,
  Drawer
} from '@mui/material';
import { AuthContext } from '../../Auth';

import moment from "moment";

// material
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';

import SyllabusPanel from '../../components/_dashboard/syllabus/SyllabusPanel';

import { tToDate, mToHours,sDiffToHours } from '../../utils/formatTime';
import { SyllabusSummary } from 'src/components/_dashboard/syllabus';
import LogForm from 'src/components/_dashboard/logs/LogForm';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import Syllabus from 'src/components/Syllabus';
import Documents from 'src/components/Documents';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from 'src/components/charts';
import { Form, Formik } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import _, { cloneDeep } from 'lodash';


// ----------------------------------------------------------------------



export default function AdminFuelManagement(props) {
  const [index, setIndex] = useState(0);

  return (
    <Page title="Flyspace | Fuel management">
      <Container key={index}>
            <Stack spacing={5}>
                <FuelManagementActionBar
                    handleDataChange={() => {
                       setIndex(index+1);
                    }}
                ></FuelManagementActionBar>
                <FuelStats></FuelStats>
                <FuelTable></FuelTable>
            </Stack>
      </Container>
    </Page>
  );
}

function FuelTable(){
    const [loading, setLoading] = useState(false);
    
    const [data, setData] = useState({
        header: [],
        body: [],
        type: ["id", "string", "string", "timestamp", "number", "number", "number", "string", "string"]
    });

    const { getUrl } = useContext(AuthContext);
  
    useEffect(() => {
        setLoading(true);
        fetch(getUrl('records','fuel')) 
        .then((res) => res.json())
        .then(
          (result) => {
            //prepare the data for the static table
            if(result?.value?.length === 0)
                return;
         
            let header = Object.keys(result.value[0]);
            let body = result.value.map(el => {
                return Object.values(el);
            }); 

            setData({...data, header: header, body: body});
          },
          (error) => {
            throw(error)
          }
        ).finally(() => { 
          setLoading(false);
        });
      },[]);


    return (
    <StaticTable 
        loading={loading}
        data={data}
    >

    </StaticTable>);
}

function FuelManagementActionBar({handleDataChange}){
    return (
    <Stack flexDirection={"row"}>
        <Box style={{marginRight:20}}>
            <FuelRecordButton
                handleSave={handleDataChange}
            ></FuelRecordButton>
        </Box>
        <Box style={{marginLeft:"auto"}}>
            <FuelReportDownloader></FuelReportDownloader>
        </Box>
    </Stack>)
}

function FuelReportDownloader(){
  const { getUrl } = useContext(AuthContext);
  const [meta, setMeta] = useState(null);
  
  const [values, setValues] = useState({
    month: undefined, 
    aircraft: undefined
  });

  const [saving, setSaving] = useState(false);
  
  const onDownload = () => {
    
    if(!values?.month || !values?.aircraft)
      return;

    setSaving(true);
    const monthObj = moment(values.month);
    
    fetch(getUrl('export/roadmap') + "&month=" + (monthObj.month()+1) + "&year=" + monthObj.year() + "&aid=" + values.aircraft) 
    .then((res) => { return res.blob(); })
    .then((data) => {
        setSaving(false);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "fp_" + values.aircraft + "_" + (monthObj.month()+1) + "_" + monthObj.year() + ".pdf";
        a.click();
    });
}

  useEffect(()=>{
    fetch(getUrl('meta', 'fuel')) 
      .then((res) => res.json())
      .then(
        (result) => {
            setMeta(result.value);
            setValues(prev => {
              return {...prev, aircraft: result.value.aircrafts[0].id}
            });
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  },[]);

  return (
    <Stack flexDirection={"row"}>
         <Box style={{marginRight:30}}>
            <Select 
              variant="standard"
              value={values.aircraft || ""}
              onChange={(event) => {
                  
                  setValues(prev => {
                    return {...prev, aircraft: event.target.value};
                  });
              }}>
              {
                  meta?.aircrafts?.map((m) => {
                    return (<MenuItem value={m.id} key={m.id}>{m.identification}</MenuItem>);
                  })
              }
              </Select>
          </Box>

          <Box>
            <MonthPicker onChange={(val) => {
                  setValues(prev => {
                    return {...prev, month:val};
                  });
            }}></MonthPicker>
          </Box>
  
          <LoadingButton variant='outlined' onClick={onDownload} loading={saving}>Download</LoadingButton>
     
    </Stack>
  )
}

function MonthPicker({onChange, disabled}){
    const lastMonth = moment().subtract("1", 'months');
    const [month, setMonth] = useState(lastMonth.format("MMM YYYY")); 
    
    let monthsNumber = 12;
    let months = [];

    for(var i=0;i<monthsNumber;i++)
    {
      months.push(lastMonth.format("MMM YYYY"));
      lastMonth.subtract("1", 'months');
    }
 
    useEffect(()=>{
      onChange(month);
    },[month]);

    return (
        <FormControl variant='standard' style={{width:"150px", marginRight:20}}>
                <Select 
                    
                value={month}
                disabled={disabled}
                onChange={(event) => {
                    setMonth(event.target.value);
                }}>
                {
                    months.map((m) => {
                    return (<MenuItem value={m} key={m}>{m}</MenuItem>);
                    })
                }
                </Select>
    </FormControl>
    );
  }



function StaticTable({loading, data}){
    return (
        <Card>
        {
          loading &&
          <CircularProgress style={
            {
              position:"absolute",
              top:"50%",
              left:"50%",
              marginLeft:"-25px",
              marginRight:"-25px"
            }
          }></CircularProgress>
        }
        <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader={true}>
                <TableHead>
                    <TableRow>
                        {
                            data.header.map(el => {
                                return (<TableCell key={_uniqueId()}>{el.charAt(0).toUpperCase() + el.slice(1)}</TableCell>);
                            })
                        }
                    </TableRow>    
                </TableHead>
                <TableBody>
                        {
                            data.body.map((row,index) => {
                                return (
                                    <TableRow key={_uniqueId()}>
                                        {
                                            row.map((val, col) => {
                                                const columnType = data.type[col];
                                                let finalVal = val ? val : "-";
                                                switch(columnType)
                                                {
                                                    case "timestamp":
                                                        finalVal = moment.unix(val).format("DD-MM-YYYY HH:mm");
                                                    break;
                                                    default:
                                                        break;
                                                }
                                                return(
                                                <TableCell key={_uniqueId()}>
                                                    {finalVal}
                                                </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>);
                            })
                        }
                </TableBody>
            </Table>  
        </TableContainer>
      </Card>
    );

}

function FuelRecordButton({handleSave}){
    const [open, setOpen] = useState(false);
    const { getUrl } = useContext(AuthContext);
    const [ saving, setSaving] = useState(false);

    const onSave = (values) => {
       
        setSaving(true);
        
        const formData  = new FormData();
        for(const name in values) {
            if(values[name])
                formData.append(name, values[name]);
        }
        
        fetch(getUrl("records","fuel"), { 
           method: 'POST',
           body: formData
        }).then((response) => response.json()).then((result) => { 
                setSaving(false);
                setOpen(false);
                handleSave();
            } 
        ).catch(
           error => console.log(error) // Handle the error response object
        );
    }

    return (
        <>
          <LoadingButton variant='outlined' onClick={() => { setOpen(true) }}>
              Add new record
          </LoadingButton>
          <Drawer anchor='right' open={open} onClose={() => {}}>
              <Container style={{marginTop:'30px'}}>
                <Box>
                  <h2>Fuel record form</h2>
                </Box>
                <Box style={{minWidth:"300px"}}>
                  <FuelRecordForm
                      formId = "fuel_add_form"
                      onSubmit={onSave}
                      onDismiss={()=> setOpen(false)}
                  ></FuelRecordForm>
                </Box>
              </Container>
          </Drawer>
        </>
    );
}

function FuelRecordForm({formId, onSubmit, onDismiss}){
    const {getUrl} = useContext(AuthContext);
    const [meta, setMeta] = useState(null);

    const initialValues = {
        type: 'FLIGHTSCHOOL',
        date: moment(),
        sum: '',
        price: '', 
        volume: '', 
        ident: '',
        gid: ''
    }
    const [values, setValues] = useState(initialValues);
    
    const onSave = () => {
      onSubmit(values);
    }
    
    useEffect(()=>{
      fetch(getUrl('meta', 'fuel')) 
        .then((res) => res.json())
        .then(
          (result) => {
              setMeta(result.value);
          },
          (error) => {
            throw(error)
          }
        ).finally(() => { 
        });
    },[]);
      
    return (
    <Stack spacing={3}>
        <FormControl  style={{marginTop:20}}>
            <FormLabel>Pick a date</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                
                value={values.date}
                ampm={false}
                onChange={(val) => {
                    
                    const date = moment(val, 'YYYY-MM-DD HH:mm');
                    if(!date.isValid())
                        return;

                    setValues(prev => {
                        return {...prev, date:date};
                    });
                }}
                renderInput={(params) => <TextField variant='standard' {...params} />}
                />
            </LocalizationProvider>
        </FormControl>
        <FormControl>
            <FormLabel>Aircraft</FormLabel>
            <Select variant='standard' value={values?.aircraft} onChange={(ev,val) => {
                setValues(prev => {
                    return {...prev, aircraft:val.props.value};
                });
            }}>
                  {
                    meta && meta.aircrafts && meta.aircrafts.map(a => {
                        return <MenuItem key={a.id} value={a.id}>{a.identification}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

        <FormControl>
            <InputLabel htmlFor="ident">Record ident</InputLabel>
            <Input  type="text" id="ident" name="ident"  value={values.ident}
              onChange={(ev) => {
                setValues(prev => {
                    return {...prev, ident:ev.target.value};
                });
            }}
            ></Input>
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="sum">The total sum amount</InputLabel>
            <Input type="text" id="sum" name="sum" value={values.sum} 
              onChange={(ev) => {
                setValues(prev => {
                    return {...prev, sum:ev.target.value};
                });
            }}
            ></Input>
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="price">The base price</InputLabel>
            <Input type="text" id="price" name="price" value={values.price}
              onChange={(ev) => {
                  setValues(prev => {
                      return {...prev, price:ev.target.value};
                  });
              }}
            ></Input>
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="volume">Total volume</InputLabel>
            <Input type="text" id="volume" name="volume" value={values.volume}
              onChange={(ev) => {
                setValues(prev => {
                    return {...prev, volume:ev.target.value};
                });
            }}
            ></Input>
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="gid">Google doc ID</InputLabel>
            <Input type="text" id="gid" name="gid" value={values.gid}
              onChange={(ev) => {
                setValues(prev => {
                    return {...prev, gid:ev.target.value};
                });
            }}
            ></Input>
        </FormControl>
        <FormControl>
          <Stack flexDirection="row" justifyContent={"center"}>
            <Button variant="outlined" size="small" style={{marginRight:'20px'}} onClick={onDismiss}>Cancel</Button>
            <LoadingButton  variant="contained" size="small" onClick={onSave}>Save</LoadingButton>
          </Stack>
        </FormControl>
    </Stack>
    )
}



export function FuelStats(){
    const [loading, setLoading] = useState(false);
    const { isLoggedIn, getUrl,user } = useContext(AuthContext);
    
    const [data, setData] = useState({
      options: BaseOptionChart(), 
      series:[]
    });
  
  
    useEffect(() => {
      setLoading(true);
      fetch(getUrl('reports','fuel')) 
      .then((res) => res.json())
      .then(
        (result) => {
            
          if(result?.value?.length > 0)
          {
                const newdata = result.value.map(funnel => {
                    
                      const aData = Object.keys(funnel.data)?.map(el => {
                        return funnel.data[el];
                      });
                      
                      return {
                        name: funnel.meta.name,
                        type: 'line',
                        data: aData
                      }
                   
                  });
  
                  const newOptions = {...data.options, labels: Object.keys(result.value[0].data)};
                  
                  setData({...data, series: newdata, options: newOptions});

                  
        }
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
        setLoading(false);
      });
    },[]);
  
  
    return (
    <Card>
      <CardHeader title="Monthly fuel burn" subheader="Internal flightschool operations only" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {
          loading &&
          <CircularProgress style={
            {
              position:"absolute",
              top:"50%",
              left:"50%",
              marginLeft:"-25px",
              marginRight:"-25px"
            }
          }></CircularProgress>
        }
        <ReactApexChart 
          type="line" 
          series={data.series} 
          options={data.options} 
          height={364} />
      </Box>
    </Card>
    );

  }
