import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, IconButton, TextField, useTheme } from '@mui/material';
import moment from 'moment';
import { DateField, DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const WeekPickerClasses = (theme) => ({
  day: { width: 36, height: 36, fontSize: theme.typography.caption.fontSize, margin: "0 2px", color: "inherit" },
  customDayHighlight: { position: "absolute", top: 0, bottom: 0, left: "2px", right: "2px", border: `1px solid ${theme.palette.secondary.main}`, borderRadius: "50%" },
  nonCurrentMonthDay: { color: theme.palette.text.disabled },
  highlightNonCurrentMonthDay: { color: "#676767" },
  today: { background: theme.palette.warning.lighter, borderRadius:"50%" },
  highlight: { background: theme.palette.primary.main, color: theme.palette.common.white, borderRadius: 0 },
  endHighlight: { extend: "highlight", borderTopRightRadius: "50%", borderBottomRightRadius: "50%" },
  firstHighlight: { extend: "highlight", borderTopLeftRadius: "50%", borderBottomLeftRadius: "50%" }
});




export function WeekPicker({onChange}){
  const inputRef = useRef();

  const theme = useTheme();
  const classes = WeekPickerClasses(theme);
  
  const [state, setState] = useState(moment())

  const handleWeekChange = (date) => {
      this.setState({ selectedDate: date.clone().startOf("isoWeek") });
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
      let dateClone = date;
      return dateClone && dateClone.isValid()
        ? `Week of ${dateClone.startOf("isoWeek").format("MMM DD")}`
        : invalidLabel;
  };
  
  const renderWrappedWeekDay = (date) => {
      const renderedDay = date?.day;
      if(!renderedDay) return null;
     
      let dateClone = moment(renderedDay).clone();
      let selectedDateClone = moment(state).clone();

      const start = selectedDateClone.startOf("isoWeek").toDate();
      const end = selectedDateClone.endOf("isoWeek").toDate();
      
      const dayIsBetween = dateClone.isBetween(start, end, null, []);
      const isToday = dateClone.isSame(moment(), "day");
       
      return <PickersDay today={isToday} selected={dayIsBetween}  day={date.day} onDaySelect={(day) => {
        setState(day)
        onChange && onChange(day)
      }}></PickersDay>
    };

      return (
        <>

          <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                  value={state}
                  slots={{
                    day: renderWrappedWeekDay,
                    textField: CustomTextField 
                  }}
                  
                  renderInput={(params) => {
                    debugger
                      const start = state.clone().startOf("isoWeek");
                      const end = state.clone().endOf("isoWeek");
                      
                      params.inputProps.value = start.format("DD MMM") + " - " + end.format("DD MMM") + " " + start.year();
                     
                      return (<TextField disabled={true}  {...params}/>)
                  }}
                  onChange={() => {}}
                  renderDay={renderWrappedWeekDay}
              />
          </LocalizationProvider>
          </>
      );
    
}

function CustomTextField(params) {
  const date = moment(params.value);
  const start = date.clone().startOf("isoWeek");
  const end = date.clone().endOf("isoWeek");
  const inputValue = start.format("DD MMM") + " - " + end.format("DD MMM") + " " + start.year();
  return (
      <TextField {...params} value={inputValue}/>
  );
}




moment.updateLocale("en", {
  week: {
    dow: 1
  }
});
