import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function tToDate(date) {
  if (date == null || !date) return 'N/A';

  const dateObj = new Date(date * 1000);

  const hours = dateObj.getUTCHours() < 10 ? `0${dateObj.getUTCHours()}` : dateObj.getUTCHours();
  const minutes =
    dateObj.getUTCMinutes() < 10 ? `0${dateObj.getUTCMinutes()}` : dateObj.getUTCMinutes();
  const month =
    dateObj.getUTCMonth() + 1 < 10 ? `0${dateObj.getUTCMonth() + 1}` : dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate() < 10 ? `0${dateObj.getUTCDate()}` : dateObj.getUTCDate();

  const dateStr = `${day}/${month}/${dateObj.getUTCFullYear()} ${hours}:${minutes}`;

  return dateStr;
}

export function mToHours(min) {
  let sign = min < 0 ? "-" : "";
  min = Math.abs(min);

  min = parseInt(min);
  
  const hours = Math.floor(min / 60);
  const hoursStr = hours < 10 ? `0${hours}` : hours;

  const minutes = min - hours * 60;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

  return `${sign}${hoursStr}H:${minutesStr}M`;
}

export function sDiffToHours(start, end)
{
  const seconds = end-start;
  return mToHours(seconds/60);
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}


