import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Alert, LoadingButton } from '@mui/lab';

// material
import {
  Stack,
  TextField,
} from '@mui/material';

import {API_URL} from "../../../Auth";


export default function LoginForm() {
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: localStorage.getItem('flyspace_email') || '',
    password: '',
  });

  const [error, setError] = useState({
    email: null,
    password: null
  })

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const handleLoginLink = async () => {
    if (state.email === '' || !/^\S+@\S+\.\S+$/.test(state.email)) {
      setError({ ...error, email: 'Please enter a valid email address' });
      return;
    }
    setError({email: null, password: null});

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}auth/generate?email=${state.email}&scope=dashboard`);
      const result = await response.json();
      localStorage.setItem('flyspace_email', state.email);
      setEmailSent(true)
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleLoginPassword = async () => {
    if(state.password?.length < 6){
      setError({ ...error, password: 'Password must be at least 6 characters' });
      return;
    }

    setError({email: null, password: null});
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('email', state.email);
      formData.append('password', state.password);
      formData.append('scope', 'dashboard');
    
      const response = await fetch(`${API_URL}auth/password`, {
        method: 'POST',
        body: formData
      });
      const result = await response.json();

      if(result?.value?.access_token)
      {
        setError({email: null, password: null});
        localStorage.setItem('flyspace_jwt', result.value.access_token);
        window.location = "/dashboard"
      }else{
        setError({email: 'Invalid credentials', password: 'Invalid credentials'});
      }
      // rest of your code
    } catch (error) {
      // handle error
    }
    setLoading(false);
}

  const handleLogin = async () => {
    if(showPassword == false) {
      await handleLoginLink();
      setEmailSent(true);
      return;
    }else{
      await handleLoginPassword();
    }

    return false;
  }

 
  return (
      <>
      {!emailSent && 
          <Stack spacing={3}>
            { 
              showPassword == false && 
                <TextField
                  fullWidth
                  error={Boolean(error.email)}
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  value={state.email}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setShowPassword(true)
                    }
                  }}
                  onChange={(e) => setState({ ...state, email: e.target.value })}
                />
            }
            {
                showPassword == true && 
                  <TextField
                    fullWidth
                    type="password"
                    label="Enter your password"
                    value={state.password}
                    error={Boolean(error.password)}
                    onChange={(e) => setState({ ...state, password: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin();
                      }
                    }}
                  />
            }
            
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading && showPassword}
              onClick={() => {
                if(showPassword == false){
                  setShowPassword(true);
                }
                else {
                  handleLogin();
                }
  
              }}
            >
              Login with password
            </LoadingButton>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              loading={loading && !showPassword}
              onClick={() => {
                handleLoginLink();
              }}
            >
              Login with email
            </LoadingButton>
          </Stack>
      }

      {emailSent ? <Alert severity="success">An email with the login was sent. Please check spam as well!</Alert> : null}
      </>
  );
}
