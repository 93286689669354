import { Icon } from '@iconify/react';
import clockOutline from '@iconify/icons-eva/clock-outline';
import arrowOutline from '@iconify/icons-eva/arrow-forward-outline';
import menuOutline from '@iconify/icons-eva/menu-outline';
import barOutline from '@iconify/icons-eva/bar-chart-2-outline';
import metaOutline from '@iconify/icons-eva/credit-card-outline';

// material
import {
  Box,
  Typography,
  MenuList,
  MenuItem,
  ListItemIcon,
  Slide,
  CircularProgress,
  Stack,
  Button,
  TextField
} from '@mui/material';
// utils
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AuthContext } from 'src/Auth';
import { LoadingButton } from '@mui/lab';


export default function EditLog({ log, onChange }) {
  const [logItem, setLogItem] = useState(log);
  const [menuItem, setMenuItem] = useState(0);

  return (
    <Box style={{ position: 'relative', overflow: 'auto', height: 'calc(100vh - 120px)' }}>
      <EditLogMenu
        show={menuItem === 0}
        onSelect={(value) => {
          setMenuItem(value);
        }}
        log={log}
      />
      <EditLogDate
        log={log}
        show={menuItem === 1}
        onBack={() => {
          setMenuItem(0);
        }}
        onChange={onChange}
      />
      {
        log.instructor != null  && log.exercise &&
        <EditLogExercise
          log={log}
          show={menuItem === 2}
          onBack={() => {
            setMenuItem(0);
          }}
          onChange={onChange}
        />
      }
      <EditLogSplitter
        log={log}
        show={menuItem === 4}
        onBack={() => {
          setMenuItem(0);
        }}
        onChange={onChange}
      />
      <EditLogMeta
        log={log}
        show={menuItem === 3}
        onBack={() => {
          setMenuItem(0);
        }}
        onChange={onChange}
      />
    </Box>
  );
}

const PanelStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: 'calc(100% - 30px)',
  height: 'calc(100vh - 100px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export function EditLogMenu({ onSelect, show, log }) {
  return (
    <Slide direction="right" in={show} mountOnEnter unmountOnExit>
      <Box style={PanelStyle}>
        <MenuList style={{ display: 'inline-block', width: '100%' }}>
          <EditLogMenuItem id={1} text="Change date" icon={clockOutline} clickHandler={onSelect} />
           { 
            log.exercise != null &&
            <EditLogMenuItem
              id={2}
              text="Change exercise"
              icon={menuOutline}
              clickHandler={onSelect}
            />
           }
          <EditLogMenuItem
            id={3}
            text="Change meta"
            icon={metaOutline}
            clickHandler={onSelect}
          />
          <EditLogMenuItem id={4} text="Split log" icon={barOutline} clickHandler={onSelect} />
        </MenuList>
      </Box>
    </Slide>
  );
}

export function EditLogMeta({ log, onBack, onChange, show }) {
  const { isLoggedIn, getUrl } = useContext(AuthContext);
  const [saving, setSaving] = useState(false);

  const [locations,setLocations] = useState([]);

  const [landings, setLandings] = useState(log.landings);
  const [from, setFrom] = useState(log.from.id);
  const [to, setTo] = useState(log.to.id);
  
 
  useEffect(() => {
    fetch(`${getUrl('locations')}&org=1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setLocations(result.value);
        },
        (error) => {
          throw error;
        }
      )
      .finally(() => {});
  },[]);
 
  const locationKeys = [];
  locations.map((loc, index) =>
    locationKeys.push({
        key: loc.id,
        text: loc.icao,
        location: loc
      })
  );

  const onSave = () => {
    setSaving(true);
    
    fetch(getUrl('log'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: `field=meta&landings=${landings}&from=${from}&to=${to}&logId=${log.id}`
    })
      .then((res) => res.json())
      .then((response) => {
        setSaving(false);
        if (response && response.value) {
          log.from = response.value.from;
          log.to = response.value.to;
          log.landings = response.value.landings;
          
          onChange('meta', log);
          onBack();
        }
    });
  };

  return (
    <Slide direction="left" in={show} mountOnEnter unmountOnExit>
      <Box style={PanelStyle}>
        <Button text="< Back" style={{ border: 0 }} onClick={onBack} />
        <Box alignContent="left" width="100%">
          { saving &&
            <CircularProgress style={{ position: 'absolute', top: '20%', left: '50%' }} />
          }
          <Box>
                <Typography variant="body2" mt={2} mb={2}>
                  Change number of landings
                </Typography>
                <TextField label="Landings" value={landings} onChange={(el,val) => setLandings(val)}></TextField>
          </Box>
          <Box>
                <Typography variant="body2" mt={2} mb={2}>
                  Change the departure location
                </Typography>
                {/* 
                <ComboBox
                    allowFreeform={false}
                    autoComplete={'on'}
                    options={locationKeys}
                    selectedKey={from}
                    onChange={(el,value) => {setFrom(value.key)}}
                />
                */}
          </Box>  
          <Box mb={5}>
                <Typography variant="body2" mt={2} mb={2}>
                  Change the arrival location
                </Typography>
                {/* 
                <ComboBox
                    allowFreeform={false}
                    autoComplete={'on'}
                    selectedKey={to}
                    onChange={(el,value) => {setTo(value.key)}}
                    options={locationKeys}
                />
                */}
          </Box>

          <Button
                text="Save"
                
                style={{ border: 0, backgroundColor: '#2cae87' }}
                onClick={onSave}
              />   
         </Box>
        
      </Box>
    </Slide> );
}

export function EditLogSplitter({ log, onBack, onChange, show }) {
  const { isLoggedIn, getUrl } = useContext(AuthContext);
  const [exercises, setExercises] = useState(null);
  const [state, setState] = useState({
    selectedKey: null,
    minutes: 0
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetch(`${getUrl('exercises')}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setExercises(result.value);
        },
        (error) => {
          throw error;
        }
      )
      .finally(() => {});
  }, []);

  const exercisesKeys = [];
  if (exercises !== null) {
    exercises.map((ex, index) =>
      exercisesKeys.push({
        key: ex.id,
        text: ex.ident + " - " + ex.name,
        exercise: ex
      })
    );
  }
  const handleChange = (ev, item) => {
    const newState = { ...state };
    newState.selectedKey = item.key;
    setState(newState);

    return true;
  };

  const handleMinutesChange = (ev, minutes) => {
    const newState = { ...state };
    newState.minutes = parseInt(minutes, 10);
    setState(newState);

    return true;
  };

  const onSave = () => {
    const { minutes } = state;
    const exercise = state.selectedKey;

    setSaving(true);
    fetch(getUrl('log'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: `field=substract&exercise=${state.selectedKey}&logId=${log.id}&minutes=${state.minutes}`
    })
      .then((res) => res.json())
      .then((response) => {
        setSaving(false);
        if (response && response.value) {
          log.end = response.value.end;
          onChange('splitter', log);
          onBack();
        }
      });
  };

  const minutes = Math.floor(log.end - log.start) / 60;
  const label = `Number of minutes no longer than ${minutes}`;

  return (
    <Slide direction="left" in={show} mountOnEnter unmountOnExit>
      <Box style={PanelStyle}>
        <Button text="< Back" style={{ border: 0 }} onClick={onBack} />
        <Box>
          {(exercises === null || saving === true) && (
            <CircularProgress style={{ position: 'absolute', top: '20%', left: '50%' }} />
          )}
          {exercises !== null && (
            <Box style={{ width: '100%' }}>
              <Typography variant="body2" mt={5} mb={2}>
                <b>Step 1:</b> Choose the exercise you want to allocate minutes
              </Typography>
              { 
                /*
                <Dropdown
                  required
                  options={exercisesKeys}
                  styles={{ border: 0 }}
                  selectedKey={state.selectedKey}
                  onChange={handleChange}
                />
                */
              }
              <Typography variant="body2" mt={5} mb={2}>
                <b>Step 2:</b> Choose the number of minutes to allocate to the new exercise
              </Typography>
              <TextField label={label} required onChange={handleMinutesChange} />

              <Typography variant="body2" mt={5} mb={2}>
                <b>Step 3:</b> Apply changes in the database. IMPORTANT: No undo action
              </Typography>
              <Button
                text="Save"
                style={{ border: 0, backgroundColor: '#2cae87' }}
                onClick={onSave}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Slide>
  );
}

export function EditLogDate({ log, show, onBack, onChange }) {
  const { isLoggedIn, getUrl } = useContext(AuthContext);

  // fetch all exercises from the API
  // we do this so we make this componenent as standalone as possible
  const [value, setValue] = useState(new Date(log.start * 1000));
  const [offset, setOffset] = useState(0);

  const [loading, setLoading] = useState(false);

  const logDate = moment(log.start * 1000).startOf('day');

  const onSave = () => {
    const newDate = moment(value);
    const diff = newDate.diff(logDate, 'days');
    const timeStamp = diff * 24 * 60 * 60;
    
    log.start += timeStamp + offset*60*60;
    log.end += timeStamp + offset*60*60;
    
    setLoading(true);

    fetch(getUrl('log'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: `field=date&days=${diff}&logId=${log.id}&offset=${offset}`
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.value) {
          onChange('date', log);
          setValue(value);
          setOffset(0);
          setLoading(false);
        }
      });
  };
  
  return (
    <Slide direction="left" in={show} mountOnEnter unmountOnExit>
      <Box style={PanelStyle}>
        <Button text="< Back" style={{ border: 0 }} onClick={onBack} />
        <Box style={{ position: 'relative' }}>
          <Stack spacing={4}>
            {
            /*
            <Calendar
              showGoToToday
              onSelectDate={(value) => {setValue(value)}}
              value={value}
            />
            */
            }

            <TextField placeholder="Integer value of the offset" required={false} type="number" onChange={(el,val) => { setOffset(val)}} value={offset}></TextField>
            <LoadingButton  variant="contained" loading={loading} onClick={onSave}>Save changes</LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Slide>
  );
}

export function EditLogExercise({ log, show, onBack, onChange }) {
  const { isLoggedIn, getUrl } = useContext(AuthContext);

  // fetch all exercises from the API
  // we do this so we make this componenent as standalone as possible
  const [value, setValue] = useState(new Date(log.start * 1000));
  const [saving, setSaving] = useState(false);
  const [exercises, setExercises] = useState(null);

  useEffect(() => {
    fetch(`${getUrl('exercises')}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setExercises(result.value);
        },
        (error) => {
          throw error;
        }
      )
      .finally(() => {});
  }, []);

  const exercisesKeys = [];
  if (exercises != null) {
    exercises.map((ex, index) =>
      exercisesKeys.push({
        key: ex.id,
        text: ex.ident + " - " + ex.name,
        exercise: ex
      })
    );
  }

  const handleChange = (ev, option) => {
    setSaving(true);

    fetch(getUrl('log'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: `field=exercise&exercise=${option.exercise.id}&logId=${log.id}`
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.value) {
          log.exercise = option.exercise;
          onChange('exercise', log);
          setSaving(false);
        }
      });
  };

  return (
    <Slide direction="left" in={show} mountOnEnter unmountOnExit>
      <Box style={PanelStyle}>
        <Button text="< Back" style={{ border: 0 }} onClick={onBack} />
        {(exercises === null || saving === true) && (
          <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />
        )}
      </Box>
    </Slide>
  );
}

export function EditLogMenuItem({ id, text, icon, clickHandler }) {
  return (
    <MenuItem onClick={() => clickHandler(id)}>
      <ListItemIcon>
        <Icon icon={icon} width={24} height={24} />
      </ListItemIcon>
      <Typography variant="body">{text}</Typography>
      <ListItemIcon style={{ marginLeft: 'auto' }}>
        <Icon icon={arrowOutline} width={18} height={18} />
      </ListItemIcon>
    </MenuItem>
  );
}
