import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Link,
  CircularProgress,
  TableHead,
  Stack,
  Button,
  CardHeader,
  Box
} from '@mui/material';
import { BaseOptionChart } from './charts';
import { AuthContext } from 'src/Auth';
import ReactApexChart from 'react-apexcharts';
import { UserContext } from 'src/pages/User';

export default function FlownHoursStats(){
    const [loading, setLoading] = useState(false);
    const { getUrl } = useContext(AuthContext);
    const {user} = useContext(UserContext);
    
    const chartRef = useRef(null);
    const [data, setData] = useState({
      options: BaseOptionChart(), 
      series:[]
    });
  
    
    useEffect(() => {
      
      setLoading(true);
      fetch(getUrl('reporting','logbook') + '&id=' + user.id) 
      .then((res) => res.json())
      .then(
        (result) => {
          
          if(result?.value?.length > 0)
          {
                const newdata = result.value.map(aircraft => {
                    
                      const aData = Object.keys(aircraft.data)?.map(el => {
                        return aircraft.data[el];
                      });
                      
                      return {
                        name: aircraft.meta.identification,
                        type: 'line',
                        data: aData.map(el => Math.ceil(el))
                      }
                   
                  });
  
                  const newOptions = {...data.options, labels: Object.keys(result.value[0].data)};
                  
                  setData({...data, series: newdata, options: newOptions});
        }else
        {
          setData({...data, series:[], options:[]})
        }
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
        setLoading(false);
      });
    },[user]);
  
  
    return (
      <Card>
      <CardHeader title="Registered hours by month" subheader="" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {
          loading &&
          <CircularProgress style={
            {
              position:"absolute",
              top:"50%",
              left:"50%",
              marginLeft:"-25px",
              marginRight:"-25px"
            }
          }></CircularProgress>
        }
        <ReactApexChart 
          ref={chartRef}
          type="line" 
          series={data.series} 
          options={data.options} 
          height={364} />
      </Box>
    </Card>
    );
  
  }