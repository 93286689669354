// ----------------------------------------------------------------------

import styled from "@emotion/styled";

export default function Lists(theme) {
  return {
    
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    }
  };
}


export const ListsOverride = styled((theme) => ({
  '@global': {
    '.scrollableListBox' : {
      overflow: 'auto',
      height:'calc(100vh - 120px)'
    }
  }
}))(() => null);

