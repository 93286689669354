import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';



import {
  Container,
  Box,
  Tabs, 
  Tab,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Avatar
} from '@mui/material';

import { AuthContext } from '../../Auth';
import Page from '../../components/Page';
import MembersReport from 'src/components/admin/MembersReport';
import { set } from 'lodash';
import AppDrawer from 'src/components/AppDrawer';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'src/hooks/useSnackbar';

export default function AdminReportsPage() {
  return (
    <Page title="Flyspace | Reports">
      <Container>
          <Stack direction={"row"} mb={2}>
            <MemberDrawer></MemberDrawer>
          </Stack>
      </Container>
      <MembersReport></MembersReport>
    </Page>
  );
}

const MemberDrawer = () => {
  const snackbar = useSnackbar();

  const { getUrl } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [state, setState] = useState({
    contract: false, 
    name: "", 
    email: "", 
    phone: "", 
    type: "STUDENT", //possible values are STUDENT, PILOT
    picture: null,
  });
  
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64data = reader.result;
      setState({
        ...state,
        picture: base64data,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }, [state]);

  const handleSave = async () => {
    const formData = new FormData();
  
    for (const key in state) {
      formData.append(key, state[key]);
    }
  
    setLoading(true);
      
    const res = await fetch(getUrl('create', 'auth'), {
      method: 'POST',
      body: formData
    }).finally(() => {
      setLoading(false);
    });

    if(!res.ok)
      snackbar.openError((await res.json()).value);
    
    setOpen(false);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <>
      <Button onClick={() => setOpen(true)} variant={"contained"}>Add new member</Button>
      <AppDrawer title={"Member details/add"} height={80} open={open} onClose={() => setOpen(false)} anchor="bottom">
        <Stack spacing={3} paddingTop={3}>

          <Stack {...getRootProps()} direction={"row"} spacing={2} width={200}>
              {state.picture && <Avatar src={state.picture} sx={{ width: 50, height: 50 }} /> }
              <Button variant="outlined"><input {...getInputProps()} />Upload picture</Button>
          </Stack>

          <TextField
            label="Name"
            name="name"
            value={state.name}
            onChange={handleChange}
          />

          <TextField
            label="Email"
            name="email"
            value={state.email}
            onChange={handleChange}
          />

          <TextField
            label="Phone"
            name="phone"
            value={state.phone}
            onChange={handleChange}
          />

          <FormControl>
            <Select id="type" name="type" value={state.type} onChange={handleChange}>
              <MenuItem value="STUDENT">Student</MenuItem>
              <MenuItem value="PILOT">Pilot</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <Box>
              <LoadingButton size={"large"} onClick={handleSave} loading={loading} variant="contained" color="primary">Submit</LoadingButton>
            </Box>
          </FormControl>
    
        </Stack>
      </AppDrawer>
    </>
  );
};