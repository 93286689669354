import { Alert, Slide, Snackbar, useTheme } from "@mui/material";
import { createContext, useContext, useState } from "react";

const SnackbarContext = createContext();

function SlideTransition(props) {   
    return <Slide {...props} direction="down" />;
}

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const openError = (message) => {
    setSeverity('error');
    setMessage(message);
    setOpen(true);
  };

  const openSuccess = (message) => {
    setSeverity('success');
    setMessage(message);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ openError, openSuccess }}>
      {children}
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          message={message}
          TransitionComponent={SlideTransition}
        >
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};