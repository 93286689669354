import React, { useContext, useState } from 'react';

import {
  Container,
  Box,
  Tabs,
  Tab
} from '@mui/material';

import { AuthContext } from '../../Auth';
import Page from '../../components/Page';
import Logbook from 'src/components/Logbook';
import { useParams } from 'react-router-dom';
import LogForm from 'src/components/_dashboard/logs/LogForm';


export default function StudentLogbookPage(props) {
  const { isLoggedIn, getUrl,user } = useContext(AuthContext);
  const [ selectedItem, setSelectedItem] = useState(null);
  const [ random, setRandom] = useState(Math.random()); 
  const [logform, setLogform] = useState(null);
  const [tab, setTab] =  useState(0);

  let { id } = useParams();
  
  const handleOnChange = (ev, log) => {
    setRandom(Math.random());
  }
  
  const filter = (tab === 0) ? "dual" : "leased";

  return (
    <Page title="Flyspace | Student Logbook">
      {
        //we should move this inside the logbook table 
        logform !== null && <LogForm exercise={logform.ex} pilot={logform.pilot} isOpen={logform !== null} onChange={handleOnChange} onCancel={() => setLogform(null)}/>
      }
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom:3 }}>
              <Tabs value={tab} onChange={(ev,val) => { setTab(val)}}>
                <Tab label="Instruction flights"/>
                <Tab label="Aircraft leases"/>
              </Tabs>
            </Box>
        
            <Logbook id={user.id} editMode={false} filterType={filter}></Logbook> 
        </Container>
    </Page>
  )
}
