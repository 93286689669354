import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';


import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Link,
  TableSortLabel,
  TableHead,
  Tooltip
} from '@mui/material';

import { AuthContext } from '../../Auth';

import Label from '../Label';

import { tToDate, mToHours } from '../../utils/formatTime';

const TABLE_HEAD = [
      { id: 'name', label: 'Name', alignRight: false },
      { id: 'last_flight', label: 'Last Flight', alignRight: false },
      { id: 'last_payment', label: 'Last Payment', alignRight: false },
      { id: 'balance', label: 'Balance', alignRight: false },
      { id: 'hours', label: 'Hours', alignRight: false },
      { id: 'phone', label: 'Phone', alignRight: false },
];

//this needs to be rewritten
export default function MembersReport({editMode, type}) {
  const {  getUrl  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [members, setMembers] = useState([]);
  
  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(getUrl('reports/' + type));
        const result = await response.json();
        debugger
        setMembers(result.value);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchReport();
  }, [type]);

  
  return (
      <Container>
        <Card>
            <TableContainer sx={{ minWidth: 800 }} >
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                      >
                          {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members.map((row) => {
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={row.name} src={row.picture} />
                              <Tooltip title={row.id}>
                              <Typography variant="subtitle2" noWrap>
                                {row.name}
                              </Typography>
                              </Tooltip>
                            </Stack>
                          </TableCell>

                          { 
                                <>
                                  <TableCell align="left"> 
                                      {tToDate(row.lastFlight)}
                                  </TableCell>
                                  <TableCell align="left">
                                      {tToDate(row.lastPayment)}
                                  </TableCell>
                                  <TableCell align="left">{mToHours(row.balance*60)}<Typography variant="caption">({row.balance})</Typography></TableCell>
                                  <TableCell align="left">{mToHours(row.hours*60)}</TableCell>
                                  <TableCell align="left">
                                      <Link href={"http://api.whatsapp.com/send?phone=" + row.phone} target="_blank">
                                        {row.phone}
                                      </Link>
                                  </TableCell>
                                </>
                          }
                        </TableRow>
                      );
                    })}
                
                </TableBody>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <CircularProgress color="secondary" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
        </Card>
      </Container>
  );
}