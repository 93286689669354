import Cookies from 'js-cookie';
import React, { useState, useEffect, useContext } from 'react';
import { API_URL, AuthContext } from 'src/Auth';

export const UserContext = React.createContext(null);

export default function UserContextProvider({ children,userId }) {
  const { getUrl, user } = useContext(AuthContext);
  
  const [state, setState] = useState({user: null});
  
  useEffect(() => {
    if(!userId || (user && userId === user.id))
    {
      setState({user});
      return;
    }
    
  fetch(getUrl('details', "auth") + "&user_id=" + userId)
      .then((res) => res.json())
      .then(
        (result) => {
          setState({...state, user: result.value})
        },
        (error) => {
          setState({
            user: user,
          })
        }
      );
  }, [userId]);
  

  return (
    <UserContext.Provider
      value={{
        user: state.user
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
