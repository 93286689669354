import { Close } from "@mui/icons-material";
import {  Box, CircularProgress, Container, Drawer, IconButton, Slide, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "src/hooks/useSnackbar";

export default function AppDrawer({message, loading, title, titleComponent, children, open, onClose, height, width}){
    //use an internal state to avoid flickers 
    const snackbar = useSnackbar();
   
    //do not render only when is first opened
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if(message != alert)
            snackbar.openSuccess(message);
    }, [message]);
    
    

    return (
        <Drawer anchor="bottom" open={open} onClose={onClose}>
            <Container sx={{height: height + "vh", paddingTop:2, paddingBottom:2, maxWidth: width + '% !important' ?? '' }}>
                <Stack direction={"row"} alignItems={"center"}>
                    {title && 
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <Typography variant="h6" fontWeight={"bold"}>
                            {title}
                        </Typography>
                        {
                            loading && 
                                <CircularProgress size={20} color={"primary"}></CircularProgress>
                        }
                        </Stack>
                    }
                    {
                        titleComponent && <>{titleComponent}</>
                    }
                    <Box marginLeft={"auto"}>
                        <IconButton onClick={onClose}>
                            <Close></Close>
                        </IconButton>
                    </Box>
                </Stack>
                {
                    children
                }
            </Container>
        </Drawer>
    );
}