import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL, AuthContext } from 'src/Auth';


export default function SharedAccess(props) {
    const { resetAccessToken } = useContext(AuthContext);

    let { perma_link } = useParams();
    

    //get access token by permalink and refresh the page
    useEffect(() => {
        fetch(`${API_URL}auth/token?perma_link=${perma_link}`)
          .then((res) => res.json())
          .then(
            (result) => {
                if(result && result.value && result.value.access_token){
                    resetAccessToken(result.value.access_token);
                }
            },
            (error) => {
             
            }
          );
      }, []);

    return (
        <></>
    );
}