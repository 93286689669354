import React, { useContext, useState, useEffect } from 'react';
import { Alert, Avatar, Autocomplete, Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemIcon, ListItemText, MenuList, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Stepper, Step, StepLabel, Switch, Table, TableBody, TableCell, Container, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Slide, FormControl, InputLabel, Skeleton } from '@mui/material';
import { DatePicker, DateTimePicker, LoadingButton, TimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AuthContext } from 'src/Auth';
import moment from 'moment';
import AppDrawer from '../AppDrawer';
import { useSnackbar } from 'src/hooks/useSnackbar';

const TABLE_HEAD = [
    { id: 'paid_by', label: 'Paid By', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'currency', label: 'Currency', alignRight: false },
    { id: 'aircraft', label: 'Aircraft', alignRight: false },
    { id: "hours", label: "Hours", alignRight: false},
    { id: "source", label: "Method", alignRight: false}
];

export default function AdminPayments() {
    const [loading, setLoading] = useState(null);
    const { getUrl } = useContext(AuthContext);
    const [ payments, setPayments] = useState(null);
    const [ page, setPage] = useState(0);
    
    const fetchPayments = async () => {
        setLoading(true);
        let result = await (await fetch(getUrl("index", 'payments') + "&page=" + page).catch(err => {})).json();
        setPayments(result.value);
        setLoading(false)
    }
    //fetch the info by id
    useEffect(() => {
        fetchPayments();
    }, [page]);

    return (
        <Container style={{width:'90%'}}>
            <PaymentFormDrawer sx={{button: {marginBottom: 2}}}></PaymentFormDrawer>
            <Card>
                <TableContainer>
                        <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    TABLE_HEAD.map((headCell) => (
                                        <TableCell key={headCell.id}>
                                            {headCell.label}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                    <TableBody>
                        {
                            loading == false && payments?.transactions?.map((payment) => {
                                return (
                                    <TableRow
                                        hover
                                        key={payment.id}
                                        aria-checked={false}
                                        >
                                        <TableCell>
                                            <Stack direction={"row"} alignItems={"center"} spacing={1}> 
                                                <Avatar alt={payment.sender.name} src={payment.sender.picture}  style={{ height: '32px', width: '32px' }}/>
                                                <Typography variant={"body2"}>
                                                    {payment.sender.name}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            { 
                                                moment(payment.date*1000).utc().format("DD/MM/YYYY")
                                            }
                                        </TableCell>
                                       
                                        <TableCell>
                                            <Typography textAlign="center" variant="body2">
                                                {payment.amount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell> 
                                            {payment.currency}
                                        </TableCell>
                                        <TableCell>
                                            {payment?.aircraft?.identification ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {payment?.hours ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                           {payment?.source}
                                        </TableCell>
                                    </TableRow>
                                )}
                            )
                        }
                        {
                            loading && 
                                <TableRow><TableCell colSpan={7}>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                </TableCell></TableRow>
                        }
                    
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={payments ? payments.total : 0}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={(el,val) => {setPage(val)}}
                    onRowsPerPageChange={() => {}}
                  />
              </Card>
          </Container> 
    )
  }


function PaymentFormDrawer({onCancel, onChange, sx}) {
    const snackbar = useSnackbar();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const { getUrl } = useContext(AuthContext);
    
    const [members, setMembers] = useState([]);
    const [agreements, setAgreements] = useState([]);
    
    const [state, setState] = useState({
        sender: null, 
        agreement: null, 
        amount: 0, 
        currency: "EUR",
        comments: "", 
        date: moment(),
        invoice: null,
    });
    
    const fetchPilots = async () => {
        setLoading(true);
        const result = await fetch(getUrl('pilots') + "&org=1&roles[]=student&roles[]=pilot").then(res => res.json()).catch(err => {});
        setMembers(result?.value ?? []);
        setLoading(false);
    }

    useEffect(() => {
       //fetchPilots();
    }, []);

    const fetchAgreements = async (memberId) => {
        setLoading(true);
        const result = await fetch(getUrl('members','agreements') + "&id=" + memberId).then((res) => res.json()).catch(err => {});
        setAgreements(result.value);
        setLoading(false);
        result?.value?.[0]?.id && setState({...state, agreement: result.value[0].id});
    }

    useEffect(() => {
        state?.sender && fetchAgreements(state.sender);
    },[state.sender])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if(!state.sender || !state.agreement || !state.amount){
          snackbar.openError("Please fill in all the required fields");
          return;
        }
        
        setLoading(true);
        const formData = new FormData();
        Object.keys(state).forEach(key => formData.append(key, state[key]));
        
        
        fetch(getUrl("index", 'payments'), {
          method: 'post',
          body: formData
        })
        .then(res => res.json())
        .then(response => response?.value && handleClose?.());
        
        setLoading(false);
    }

    return (
        <>
            <Button variant="contained" sx={{...sx.button}} onClick={() => setOpen(true)}>Register payment</Button>

            <AppDrawer loading={loading} title={"Add new payment NEED REBUILD !!!"} fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
                    <Stack spacing={4} mt={2} direction="column">
                            <Stack direction="row" spacing={2} justifyContent={"normal"}>
                                <FormControl style={{ flex: 1 }}>
                                    <Autocomplete disablePortal
                                        isOptionEqualToValue={(option, value) => { return option.id === value.id}}
                                        getOptionLabel={(option) => option.label || ""}
                                        id="sender"
                                        options={members.map((el,index) => ({label: el.name, id: el.id, contract: el.contract === 0 ? false : true}))}
                                        onChange={(ev,val) =>  { 
                                            setState({...state, sender: val.id});
                                        }}
                                        renderInput={(params) => <TextField {...params} label="From" />}
                                    />
                                </FormControl>
                                <FormControl style={{ flex: 1 }}>
                                    <InputLabel id="agreements-label">Agreement</InputLabel>
                                    <Select
                                        labelId="agreements-label"
                                        id="type"
                                        value={state?.agreement ?? ""}
                                        label="Agreement"
                                        onChange={(ev) => { 
                                            setState({...state, agreement: ev.target.value});
                                        }}
                                    >
                                        {
                                            agreements.map(el => (
                                                <MenuItem value={el.id}>
                                                {`${el.type}-${el.from.name}${el.aircraft ? `-${el.aircraft.identification}` : ''}-${el.price} ${el.currency}`}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControl style={{flex:1}}>
                                    <TextField label="Amount" required={true} 
                                        onChange={(ev)=> { 
                                            setState({...state, amount: ev.target.value});
                                        }} 
                                        value={state.amount}></TextField> 
                                </FormControl>
                                <FormControl style={{flex:1}}>
                                    <InputLabel id="currency">Currency</InputLabel>
                                        <Select
                                            labelId="currency"
                                            id="currency"
                                            value={state.currency}
                                            label="Currency"
                                            onChange={(ev) => { setState({...state, currency: ev.target.value}); }}
                                        >
                                            <MenuItem value={"EUR"}>EUR</MenuItem>
                                            <MenuItem value={"RON"}>RON</MenuItem>
                                        </Select>
                                </FormControl>
                            </Stack>
                            
                            <FormControl>
                                <TextField
                                    placeholder="Comments"
                                    multiline
                                    rows={6}
                                    maxRows={4}
                                    onChange={(ev) => { setState({...state, comments: ev.target.value});}}
                                    />
                            </FormControl>
                    </Stack>
                    <Stack direction={"row"} mb={5} mt={1}>
                        <LoadingButton onClick={handleSave} loading={loading} variant='contained'>Save</LoadingButton>
                    </Stack>
            </AppDrawer>
        </>
    );
}

  

