import { Icon } from '@iconify/react';
import { useContext, useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import data from '@iconify/icons-ic/access-alarm';
import menu from '@iconify/icons-ic/baseline-menu';
import calendar from '@iconify/icons-ic/baseline-calendar-today';
import payment from '@iconify/icons-ic/baseline-payment';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import enterOutline from '@iconify/icons-eva/corner-down-left-outline';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import { AuthContext } from 'src/Auth';


// ----------------------------------------------------------------------
export function SlotCalendarMoreMenu({onClick, objKey})
{
  const menuItems = [
    {
      text: "Mark as unavailable",
      to: 'unavailable', 
      visible: true
    }
  ];

  return (
    <MoreMenu menuItems={menuItems} onClick={onClick} objKey={objKey}></MoreMenu>
  );
}
export function GoalsMoreMenu({onClick, objKey, joined, done, priv, archived})
{
  const menuItems = [
    {
      text: joined ? "Leave team" : "Join team",
      to: joined ? 'leave' : 'join', 
      visible: true
    },
    {
      text: "Mark as done",
      to: 'join', 
      visible: true
    },
    {
      text: "Mark as private",
      to: 'join', 
      visible: true
    },
    {
      text: "Archive item",
      to: 'join', 
      visible: true
    }
  ];

  return (
    <MoreMenu menuItems={menuItems} onClick={onClick} objKey={objKey}></MoreMenu>
  );
}


export function DocumentsMoreMenu({onClick, objKey, requested})
{
  if(requested == true){
    //debugger
  }
  const menuItems = [
    {
      to: requested ? "cancel" : "request", 
      icon: <Icon icon={enterOutline} width={24} height={24} />,
      text: requested ? "Cancel request" :  "Request document" ,
      visible: true
    }
  ];

  return (
    <MoreMenu menuItems={menuItems} onClick={onClick} objKey={objKey}></MoreMenu>
  );
}

export function UpdatesMoreMenu({uid, onClick})
{
  const url = "archive";
  const menuItems = [
    {
      to: url, 
      icon: <Icon icon={data} width={24} height={24} />,
      text: "Archive item",
      visible: true
    }
  ];
  
  return (
    <MoreMenu menuItems={menuItems} onClick={onClick}k></MoreMenu>
  );
}


export function MoreMenu({menuItems, onClick, objKey}){
  const [isOpen, setIsOpen] = useState(false);
  const nullRef = useRef(null);
  const [ref2, setRef2] = useState(nullRef);
  

  return (
    <>
      <IconButton ref={ref2} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        style={{zIndex:10000000}}
        open={isOpen}
        anchorEl={ref2.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {
          menuItems.map((item, key) => {
            if(item.visible === false)
              return (<></>);

            return (
            <MenuItem key={key} sx={{ color: 'text.secondary' }}  component={Link} to={onClick ? '#' : item.to} onClick={onClick ? () => {
                onClick(item.to, objKey)
                setIsOpen(false);
                return true;  
              } : null}>
              { item.icon && 
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
              }
              <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>)
          })
        }
      </Menu>
    </>
  );
}
