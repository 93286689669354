export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
          borderRadius: theme.shape.borderRadiusMd,
          //boxShadow: theme.customShadows.z16,
          position: 'relative',
          zIndex: 0 // Fix Safari overflow: hidden with border radius
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    }
  };
}

export function TableHead(theme){
  return {
    MuiTableHead : {
      styleOverrides : {
        root: {
          backgroundColor: theme.palette.grey[100]
        }
      }
    }
  }
}
/*
export const UpdateCardOverrides = withStyles((theme) => ({
  '@global': {
    '.updateCard' : {
      marginTop: '30px'
    },
    '.updateCard .date': {
      marginLeft: 'auto'
    }
  }
}))(() => null);
*/