import React, { useContext } from 'react';
import { AuthContext } from '../../Auth';
import Page from '../../components/Page';
import Documents from 'src/components/Documents';

export default function StudentDocumentsPage(props) {
  const { user } = useContext(AuthContext);
 
  return (
    <Page title="Flyspace | Documents">
        <Documents id={user.id} editMode={true}></Documents>
    </Page>
  )
}
