import React, { useContext, useState, useEffect } from 'react';

// material
import { Box, Stack } from '@mui/material';

import { AuthContext } from '../Auth';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const styles = {
    avatarChip : {
        '& .MuiChip-label':{
            paddingLeft:'0'
        }
    },
    chipLabel: {
        paddingTop:'3px',
        marginLeft:'0px !important'
    }
}

function PeoplePicker({classes, onChange, value}) {
  const { isLoggedIn, getUrl, user } = useContext(AuthContext); 
  const theme = useTheme();
  const [personNames, setPersonNames] = React.useState([]);

  const [people, setPeople] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    fetch(getUrl('community', 'members')) 
      .then((res) => res.json())
      .then(
        (result) => {
            let people = [];
            let selected = [];
            result.value.forEach(element => {
                const pObj = {
                    id : element.id,
                    name: element.name, 
                    picture: element.picture
                };

                people.push(pObj);
                
                value.forEach(sel => {
                    if(sel === element.id)
                    {
                        selected.push(pObj);
                    }
                })
            });

            setPeople(people);
            setPersonNames(selected);
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    
    setPersonNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
    onChange(value);
  };

  return (
      <>
        <InputLabel id="person-picker">Person Picker</InputLabel>
        <Select
          labelId="person-picker"
          id="person-picker"
          multiple
          value={personNames}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => {
            
            return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
              selected.map((value) => (
                    <Chip key={value.id} className={classes.avatarChip} label={
                        <Stack direction="row" spacing={5}>
                            <Avatar 
                                alt={value.name} 
                                src={value.picture} 
                                style={{borderRadius: "30px", width:'30px', height:'30px', marginRight:'10px', marginLeft:0}} 
                            />
                            <Box className={classes.chipLabel}> 
                                {value.name}
                           </Box>
                        </Stack>
                    } />
              ))}
            </Box>
          )}}
          MenuProps={MenuProps}
        >
          {people.map((p) => (
            <MenuItem
              key={p.id}
              value={p}
              style={getStyles(p.name, personNames, theme)}
            >
              {p.name}
            </MenuItem>
          ))}
        </Select>
    </>
  );
}


export default PeoplePicker;