import React, { useContext, useState } from 'react';
import {
  Box,
  Tabs, 
  Tab,
  Container
} from '@mui/material';
import { AuthContext } from '../../Auth';
import Page from '../../components/Page';
import {GoalsForm} from 'src/components/Goals';
import { useFormik } from 'formik';


function tabsProps(index) {
return {
    id: `forms-tab-${index}`,
    'aria-controls': `forms-tabpanel-${index}`,
};
}

//put this as a generic component to be reused
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`forms-tabpanel-${index}`}
        aria-labelledby={`forms-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
              {children}
          </Box>
        )}
      </Box>
    );
  }

export default function AdminFormsPage(props) {
  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
  const [ tab, setTab] = useState(0);
  
  return (
    <Page title="Flyspace | Admin forms">
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(ev,val) => { setTab(val)}} aria-label="basic tabs example">
                    <Tab label="Community Goals" {...tabsProps(0)} />
                    <Tab label="Member" {...tabsProps(1)} />
                    <Tab label="Financial agreements" {...tabsProps(1)} />
                </Tabs>
                </Box>
                <TabPanel value={tab} index={0}>
                    <GoalsForm></GoalsForm>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <MemberForm></MemberForm>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    Coming soon
                </TabPanel>
        </Container>
    </Page>
  );
}

function MemberForm(){
  const formik = useFormik({
    initialValues: {
      name: "", 
      email: '',
      phone: '+',
      type: 'STUDENT',
      picture: '',
      contract : false
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

    return (<>
      asdsad
    </>)
}



