import React, { useContext, useState, useEffect } from 'react';

import {
  Table,
  TableHead,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';

import { AuthContext } from '../Auth';
import moment from "moment";


export default function Payments({id, adminMode}) {
    const { isLoggedIn, getUrl } = useContext(AuthContext);
    const [ payments, setPayments] = useState(null);
    
    
    const TABLE_HEAD = [
        { id: 'date', label: 'Date', alignRight: false },
        { id: 'amount', label: 'Amount', alignRight: false },
        { id: 'currency', label: 'Currency', alignRight: false },
        { id: 'aircraft', label: 'Aircraft', alignRight: false },
        { id: "hours", label: "Hours", alignRight: false},
        { id: "source", label: "Method", alignRight: false}
    ];
    
      
    //fetch the info by id
    useEffect(() => {
      fetch(getUrl('index', "payments") + "&id=" + id) 
        .then((res) => res.json())
        .then(
          (result) => {
              setPayments(result.value);
          },
          (error) => {
            throw(error)
          }
        ).finally(() => { 
        });
      
    }, [id]);

    return (
          <Container>
                    <TableContainer sx={{border:1, borderColor:"grey.300", borderRadius:'10px'}}>
                        <Table>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.alignRight ? 'right' : 'left'}
                                >
                                    {headCell.label}
                                </TableCell>
                                ))
                                }
                            </TableRow>
                        </TableHead>
                    <TableBody>
                        {
                            payments !== null && payments.transactions.length > 0 ? payments.transactions.map((payment, index) => {

                                const href = (payment.invoice) ? getUrl("documents/download") + "&id=" + payment.invoice : null;

                                
                                return (
                                    <TableRow
                                    hover
                                    key={payment.id}
                                    tabIndex={-1}
                                    aria-checked={false}
                                    >
                                        <TableCell>
                                            { 
                                                moment(payment.date*1000).utc().format("DD/MM/YYYY")
                                            }
                                        </TableCell>
                                       
                                        <TableCell>
                                            <Typography textAlign="center" variant="body2">
                                                {payment.amount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell> 
                                            {payment.currency}
                                        </TableCell>
                                        <TableCell>
                                            {payment?.aircraft?.identification ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {payment?.hours ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                           {payment?.source}
                                        </TableCell>
                                </TableRow>)
                                
                            }
                                )

                            : 

                            (
                                <TableRow>
                                    <TableCell scope="row" align="left" colSpan="6">
                                        <Typography align="center">No payments yet</Typography>
                                    </TableCell>
                                    
                                </TableRow>
                            )
                        }
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
              
          </Container> 
    )
  }


  

