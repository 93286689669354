import {useState, useContext} from 'react';

import { 
    Box,
    Tabs,
    Tab,
    Slide,
    Drawer
} from '@mui/material';

import { AuthContext } from '../../../Auth';
import { LogInfo } from '../logs';
import EditLog from '../logs/EditLog';


const SyllabusPanelStyle = {
    position:'absolute', 
    top:'0', left:'0', 
    width:'calc(100% - 30px)', 
    height: 'calc(100vh - 100px)'
}

export default function SyllabusPanel({isOpen, onDismiss, logObj, onChange, edit}){
  const [updates, setUpdates] = useState([]);
  const [log, setLog] = useState(logObj);

  const {getUrl} = useContext(AuthContext);
  const [step, setStep] = useState(1);
    
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
        <Drawer id="sideDetails"
            styles={{
                scrollableContent: {
                    overflow: "hidden"
                },
                content: {
                    overflow: "hidden",
                    padding: 0
                }
            }}
            isOpen={isOpen}
            onDismiss={onDismiss}
            headerText="Log details"
            closeButtonAriaLabel="Close"
            isHiddenOnDismiss={true}
            isFooterAtBottom={true}
        >
            { edit && 
                <Box sx={{ maxWidth: '100%', borderBottom: '1px solid #f1f1f1'}} ml={2} mr={2}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        scrollButtons="auto"
                    >
                        <Tab label="Log info"/>
                        <Tab label="Actions" />
                    </Tabs>
                </Box>
            }
            <Box style={{position:'relative', top: '0', left:'0', height: 'calc(100vh)'}}>
                <Slide direction="left" in={value === 1} mountOnEnter unmountOnExit>
                        <Box mt={2} ml={2} mr={2} style={SyllabusPanelStyle}>
                            <EditLog log={log} onChange={onChange}/>
                        </Box>
                </Slide>
                <Slide direction="right" in={value === 0} mountOnEnter unmountOnExit>
                        <Box mt={2} ml={2} mr={2} style={SyllabusPanelStyle}>
                            <LogInfo log={log}/>
                        </Box>
                </Slide>
            </Box>
        </Drawer>
        
    );
}


