import React, { useContext, useState, useEffect } from 'react';

import { Link as RouterLink, useNavigate, useParams,useLocation } from 'react-router-dom';

import { AuthContext } from '../Auth';

// material
// components
import Page from '../components/Page';
import { Box,Typography,Card, Container,Stack,Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import Logbook from '../components/Logbook';
import { Link } from "react-router-dom";
import Syllabus from 'src/components/Syllabus';
import Briefing from 'src/components/Briefing';
import FlownHoursStats from 'src/components/Stats';
import { DashboardManifest } from 'src/components/DashboardManifest';
import { mToHours } from 'src/utils/formatTime';
import UserContextProvider, { UserContext } from './User';

// ----------------------------------------------------------------------


export default function TrainingsPage(props) {
  let { training_id,widget, user_id } = useParams();
  training_id = parseInt(training_id);
  const userId = parseInt(user_id);

  widget = (!widget) ? "trainings" : widget;
  if(widget !== "trainings" && widget !== "syllabus" && widget !== "logbook" && widget !== "briefing")
    widget = "trainings";
    
  return (
    <Page title="Flyspace | Trainings">
        <UserContextProvider userId={userId}>
            <TrainingsDashboard widget={widget} training={training_id}></TrainingsDashboard>
        </UserContextProvider>
    </Page>
  )
}

function TrainingsDashboard({widget, training}){
    const { user } = useContext(UserContext);
    const { getLoggedUser } = useContext(AuthContext);

    return ( 
    <Container>
        <Stack spacing={10}>
           {
            user === null && <CircularProgress></CircularProgress>
           }
        
           {
            user !== null && widget === "trainings" && <TrainingsList></TrainingsList>
           }
           {
            user !== null && widget === "syllabus" && <TrainingsSyllabus training_id={training}></TrainingsSyllabus>
           }
            {
            user !== null && widget === "briefing" && <TrainingsBriefing training_id={training}></TrainingsBriefing>
           }
           {
            user !== null && widget === "logbook" && <TrainingsLogbook training_id={training}></TrainingsLogbook>
           }
        
        
        </Stack>
    </Container>);
}

function TrainingsLogbook({training_id}){
    const { user } = useContext(UserContext);
 
    return (
        <>
            <TrainingsBackButton></TrainingsBackButton>
            <Logbook id={user.id} editMode={false} filterType={"dual"} tid={training_id}></Logbook>
        </>
    );
}

function TrainingsSyllabus({training_id}){
    const { user } = useContext(UserContext);

    return ( 
        <>
            <TrainingsBackButton></TrainingsBackButton>
            <Syllabus id={user.id} editMode={false} tid={training_id}/>
    </>);   
}

function TrainingsBriefing({training_id}){
    const { user } = useContext(UserContext);

    return ( 
    <>
        <TrainingsBackButton></TrainingsBackButton>
        <Briefing id={user.id} editMode={false} trainingId={training_id}/>
    </>);
}

function TrainingsBackButton()
{
    return (
        <Box>
            <Button variant="outlined" component={Link} to="/trainings/">Back to trainings</Button>
        </Box>
    );
}



export function TrainingsList({hashRedirect, onRouteChanged}) {
    //choose context 
    const {  getUrl } = useContext(AuthContext);
    const { user } = useContext(UserContext);
    
    const [ trainings, setTrainings] = useState([]);
  
    const TABLE_HEAD = [
        { id: 'id', label: 'Id', alignRight: false },
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'flight_hours', label: 'Flight hours', alignRight: false },
        { id: 'briefing_hours', label: 'Briefing hours', alignRight: false },
        { id: 'syllabus_button', label: 'Syllabus', alignRight: false },
        { id: 'briefings_button', label: 'Briefings', alignRight: false },
        { id: 'logbook_button', label: 'Logbook', alignRight: false }
    ];

    useEffect(() => {
        if(user == null)
            return;

        fetch(getUrl('report', 'trainings') + "&id=" + user.id) 
          .then((res) => res.json())
          .then(
            (result) => {
                setTrainings(result.value);
            },
            (error) => {
              throw(error)
            }
          ).finally(() => { 
          });
      }, [user]);

   
    return (
            <Card sx={{ width: '100%' }}>
                
                <TableContainer sx={{ width: '100%'}}>
                        <Table>
                        <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.alignRight ? 'right' : 'left'}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                        ))
                                        }
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        trainings && trainings.map((training, index) => {
                                            let baseUrl = "trainings/" + user.id + "/training/" + training.id + "/";
                                            baseUrl = hashRedirect ? "#" + baseUrl : "/" + baseUrl;


                                            return (
                                                <TableRow  key={training.id} tabIndex={-1} aria-checked={false}>
                                                    <TableCell>
                                                        {training.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {training.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                            {mToHours(training.flightHours*60)}
                                                    </TableCell>
                                                    <TableCell>
                                                            {mToHours(training.briefingHours*60)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='outlined' size='small' component={Link} to={baseUrl + "syllabus"} onClick={() => {
                                                            if(onRouteChanged)
                                                                onRouteChanged(baseUrl + "syllabus");
                                                            return true;
                                                        }}>Syllabus</Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='outlined' size='small' component={Link} to={baseUrl + "briefing"} onClick={() => {
                                                            if(onRouteChanged)
                                                                onRouteChanged(baseUrl + "briefing");
                                                            return true;
                                                        }}>Briefing</Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='outlined' size='small' component={Link} to={baseUrl + "logbook"} onClick={() => {
                                                            if(onRouteChanged)
                                                                onRouteChanged(baseUrl + "logbook");
                                                            return true;
                                                        }}>Logbook</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }     
                                    {
                                        (!trainings || trainings.length === 0)  && 
                                        <TableRow>
                                            <TableCell colSpan={6} style={{textAlign:"center"}}>No trainings loaded</TableCell>
                                        </TableRow>
                                    } 
                                </TableBody>           
                            <TableBody>
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Card>
    );
}
