import { Icon } from '@iconify/react';
import clockOutline from '@iconify/icons-eva/clock-outline';
import personOutline from '@iconify/icons-eva/person-outline';
import globeOutline from '@iconify/icons-eva/globe-outline';
// material
import { Box, Grid, Card, Paper, Typography, CardHeader, CardContent, List, ListItem,ListItemText } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { tToDate, mToHours,sDiffToHours } from '../../../utils/formatTime';
import { useState } from 'react';
import moment, { relativeTimeRounding } from 'moment';

import {ListsOverride} from '../../../theme/overrides/Lists.js';

// ----------------------------------------------------------------------


export default function LogInfo({log}) {
  const [state, setState] = useState(log);

  return (
    <Box className="scrollableListBox">
        <ListsOverride/>
        <List>
            <ListItem>
                <ListItemText
                primary="ID"
                secondary={log.id}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Aircraft"
                secondary={log.aircraft.identification}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Start"
                secondary={moment(log.start*1000 - log.warmup*60*1000).utc().format("DD/MM/YYYY HH:mm")}
                />
            </ListItem> 
            <ListItem>
                <ListItemText
                primary="End"
                secondary={moment(log.end*1000).utc().format("DD/MM/YYYY HH:mm")}
                                            
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="From"
                secondary={log.from.name}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="To"
                secondary={log.to.name}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Exercises"
                secondary={log.exercise ? log.exercise.name : "-"}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Flights/Landings"
                secondary={log.landings}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Warmup minutes"
                secondary={log.warmup}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Flight time"
                secondary={sDiffToHours(log.start,log.end)}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Total time"
                secondary={mToHours((log.end-log.start)/60 + log.warmup)}
                />
            </ListItem>
            
        </List>
        <Card style={{backgroundColor: "#f9f9f9"}}>
            <CardHeader title="Log comment" />
            <CardContent>
                <div dangerouslySetInnerHTML={{ __html: log.comments }} />
            </CardContent>
        </Card>
    </Box>
  );
}
