import React, { useContext, useState, useEffect } from 'react';
import { uniqueId } from 'lodash';

import {
  Card,
  Table,
  TableHead,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';

import { AuthContext } from '../Auth';

import moment from "moment";

import Scrollbar from './Scrollbar';
import SyllabusPanel from './_dashboard/syllabus/SyllabusPanel';
import { mToHours } from '../utils/formatTime';
import LogForm from 'src/components/_dashboard/logs/LogForm';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'aircraft', label: 'Aircraft', alignRight: false },
  { id: 'instructor', label: 'Instructor', alignRight: false },
  { id: 'Duration', label: 'Duration', alignRight: false }
];

export default function Briefing({id, editMode, trainingId}) {

  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
  const [ state, setState] = useState({isLoading: true, syllabus: null});
  const [ selectedItem, setSelectedItem] = useState(null);
  const [ random, setRandom] = useState(Math.random());
  const [ logform, setLogform] = useState(null);

  const handleOnChange = (ev, log) => {
    setRandom(Math.random());
  }

  //fetch the info by id  
  useEffect(() => {
    fetch(getUrl('briefing', "trainings") + "&filter=dual&id="+ id + "&tid=" + trainingId)
        .then((res) => res.json())
        .then(
        (result) => {
            setState({
                isLoading: false, 
                syllabus:result.value
            });
        },
        (error) => {
            throw(error)
        }
        ).finally(() => { 
        });
    
   }, [random,id]);
 
  const handleAddNew = (ex) => {
    setLogform({
      ex: ex, 
      pilot: state.syllabus.owner
    });
  }
  const edit = (user.admin === 1) ? true : false;
  
  return (
      <>
        {
            logform !== null && <LogForm exercise={logform.ex} pilot={logform.pilot} isOpen={logform !== null} onChange={handleOnChange} onCancel={() => setLogform(null)}/>
        }
        {
            selectedItem &&
            <SyllabusPanel 
            key={uniqueId()}
            isOpen={selectedItem !== null}
            onDismiss={() => setSelectedItem(null) }
            logObj = {selectedItem}
            onChange = {handleOnChange}
            edit = {edit}
            />
        }
       

            
            {
                
                state.syllabus && state.syllabus && state.syllabus.topics.map((topic,index) => {
                
                    let color = "";

                return ( 
                    <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} mt={5}>
                                <Typography variant="h4" gutterBottom color={color}>
                                {`${topic.name}`}
                                </Typography>

                                { 
                                user.admin === 1 ? 
                                    <Button key={uniqueId()} variant="contained" onClick={() => handleAddNew()}>
                                    Add new
                                    </Button>
                                    :
                                    <></>
                                }
                                
                            </Stack>
                            <Card>
                                <Scrollbar>
                                <TableContainer sx={{ width: '100%'}}>
                                    <Table>
                                            <TableHead>
                                                <TableRow key={uniqueId()}>
                                                    {TABLE_HEAD.map((headCell) => (
                                                    <TableCell
                                                    key={uniqueId()}
                                                        align={headCell.alignRight ? 'right' : 'left'}
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                    ))
                                                    }
                                                </TableRow>
                                            </TableHead>
                                    <TableBody>
                                    {
                                        state.syllabus && state.syllabus.briefings.map((brief,index) =>
                                        {
                                            if(brief.topic.id !== topic.id)
                                                return;

                                            return (<TableRow key={uniqueId()}
                                                hover
                                                key={brief.id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                aria-checked={false}
                                            >
                                                <TableCell  key={uniqueId()} component="th" scope="row" align="left" onClick={() => handleOpenPanel()}>
                                                    {brief.id}
                                                </TableCell>
                                                <TableCell  key={uniqueId()} align="left"  onClick={() => handleOpenPanel()}>
                                                    {moment(brief.date*1000).utc().format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell key={uniqueId()} align="left" onClick={() => handleOpenPanel()}> 
                                                    {brief.aircraft.identification}
                                                </TableCell>
                                                <TableCell key={uniqueId()} align="left" onClick={() => handleOpenPanel()}>
                                                    {brief.instructor.name}
                                                </TableCell>
                                                <TableCell key={uniqueId()} align="left" onClick={() => handleOpenPanel()}>
                                                    {mToHours(brief.minutes)}
                                                </TableCell>
                                               
                                            </TableRow>);
                                        }
                                        )
                                        }
                                        
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                </Scrollbar>
                            </Card>
                            
                            </Container>
                        );

                })
            }

       
       
    </>
  );


  function handleOpenPanel(log) {
    setSelectedItem(log)
  }
}
