import React, { useContext, useState, useEffect } from 'react';

import {
  Card,
  Table,
  TableHead,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';

import { AuthContext } from '../Auth';

import moment from "moment";

// material
// components
import Scrollbar from './Scrollbar';
import SyllabusPanel from './_dashboard/syllabus/SyllabusPanel';
import { mToHours,sDiffToHours } from '../utils/formatTime';
import { SyllabusSummary } from 'src/components/_dashboard/syllabus';
import LogForm from 'src/components/_dashboard/logs/LogForm';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'aircraft', label: 'Aircraft', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'from', label: 'From', alignRight: false },
  { id: 'to', label: 'To', alignRight: false },
  { id: 'flight_time', label: 'Flight time', alignRight: false },
  { id: 'landings', label: 'Flights/Landings', alignRight: false },
  { id: 'instructor', label: 'Instructor', alignRight: false }
];

export default function Syllabus({id, tid}) {

  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
  const [ state, setState] = useState({isLoading: true, syllabus: null});
  const [ selectedItem, setSelectedItem] = useState(null);
  const [ random, setRandom] = useState(Math.random());
  const [ logform, setLogform] = useState(null);

  const handleOnChange = (ev, log) => {
    setRandom(Math.random());
  }
  
  
 let path = "&filter=dual&id="+ id;
 if(tid)
         path += "&tid=" + tid;


  //fetch the info by id  
  useEffect(() => {
    fetch(getUrl('syllabus', 'trainings') + path)
        .then((res) => res.json())
        .then(
        (result) => {
            debugger
            setState({
            isLoading: false, 
            syllabus:result.value
            });
        },
        (error) => {
            debugger
            throw(error)
        }
        ).finally(() => { 
            debugger
        });
    
   }, [random,id]);
 
  const handleAddNew = (ex) => {
    setLogform({
      ex: ex, 
      pilot: state.syllabus.owner
    });
  }
  const edit = (user.admin === 1) ? true : false;

  return (
      <>
        {
            logform !== null && <LogForm exercise={logform.ex} pilot={logform.pilot} isOpen={logform !== null} onChange={handleOnChange} onCancel={() => setLogform(null)}/>
        }
        {
            selectedItem &&
            <SyllabusPanel 
            isOpen={selectedItem !== null}
            onDismiss={() => setSelectedItem(null) }
            logObj = {selectedItem}
            onChange = {handleOnChange}
            edit = {edit}
            />
        }
        <Container style={{marginTop:10,padding:0}}>
            {
                state && state.syllabus &&  <SyllabusSummary summary={state.syllabus && (state.syllabus.summary)} minimums={state.syllabus && (state.syllabus.minimums)}/>
            }
           
        </Container>
        
        {
            state.syllabus && state.syllabus.exercises.map((ex, index) => {
                const logs = state.syllabus.logbook[ex.id];
                let color = "";
                if(logs && logs.total_minutes - ex.flights*ex.minutes < 0 || !logs || logs.total_landings < ex.flights)
                    color = "error";
                
                            return (
                            <Container key={ex.id} style={{widht:'90%'}}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} mt={5}>
                                <Typography variant="h4" gutterBottom color={color}>
                                {`${ex.type} - ${ex.name}`}
                                </Typography>
                            </Stack>
                            <Card>
                                <TableContainer sx={{ width: '100%'}}>
                                    <Table>
                                    <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell><Typography color={color}>Total: {mToHours(logs ? logs.total_minutes : 0)}/{logs ? logs.total_landings : 0}</Typography></TableCell>
                                                <TableCell><Typography color={color}>Miminum hours: {mToHours(ex.flights * ex.minutes)}</Typography></TableCell>
                                                <TableCell><Typography color={color}>Minimum flights: {ex.flights }</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={headCell.alignRight ? 'right' : 'left'}
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                                ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                    <TableBody>
                                        {
                                        logs && logs.flights.map((log,index) =>
                                            <TableRow
                                                hover
                                                key={log.id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                aria-checked={false}
                                            >
                                                <TableCell component="th" scope="row" align="left" onClick={() => handleOpenPanel(log)}>
                                                {log.aircraft.identification}
                                                </TableCell>
                                                <TableCell align="left"  onClick={() => handleOpenPanel(log)}>
                                                    {moment(log.start*1000).utc().format("DD/MM/YYYY HH:mm")}
                                                </TableCell>
                                                <TableCell align="left" onClick={() => handleOpenPanel(log)}> 
                                                    {log.from.icao}
                                                </TableCell>
                                                <TableCell align="left" onClick={() => handleOpenPanel(log)}>
                                                    {log.to.icao}
                                                </TableCell>
                                                <TableCell align="left" onClick={() => handleOpenPanel(log)}>
                                                    {sDiffToHours(log.start,log.end)}
                                                </TableCell>
                                                <TableCell align="left" onClick={() => handleOpenPanel(log)}>
                                                    {log.landings}
                                                </TableCell>
                                                <TableCell align="left" onClick={() => handleOpenPanel(log)}>
                                                    {log.instructor && log.instructor.name}
                                                </TableCell>
                                            </TableRow>
                                        )
                                        }
                                        {
                                        (!logs || logs.length === 0) &&
                                        ( 
                                            <TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography align="center">No logs </Typography>
                                            </TableCell>
                                            </TableRow>
                                        )
                                        }
                                        
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                            
                            </Container> 
                            );
            }
        )
        }
    </>
  );


  function handleOpenPanel(log) {
    setSelectedItem(log)
  }
}
