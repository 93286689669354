import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../Auth';

// material
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel
} from '@mui/material';

import Scrollbar from './Scrollbar';
import { cloneDeep } from 'lodash';

export default function LogbookFilter({open, onApply}) {
  const [isOpen, setIsOpen] = useState(false); 
  const [hasFilter, setHasFilter] = useState(false);

  return (
    <>
      <Stack direction="row" justifyContent={"right"}>
        {
          hasFilter &&
            <Button color="inherit" size="small" style={{marginRight:"20px"}} endIcon={<Icon icon={closeFill}></Icon>} onClick={()=>{
                setHasFilter(false);
                onApply(null);
              }}
            >
                Clear filters
            </Button>
        }
        <Button
          disableRipple
          variant={hasFilter? "contained" : 'standard'}
          onClick = {() => setIsOpen(true)}
          endIcon={<Icon icon={roundFilterList} />}
        >
            Filters
        </Button>
      </Stack>

      <LogbookFilterDrawer
        open={isOpen}
        oncCLose={() => setIsOpen(false)}
        onDismiss={() => setIsOpen(false)}
        onApply={(filter) => {
          setIsOpen(false);
          setHasFilter(true);
          onApply(filter);
        }}
      ></LogbookFilterDrawer>
    </>
  );
}


function LogbookFilterDrawer({onApply,onDismiss, open}){
  const {  getUrl } = useContext(AuthContext);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({
    "aircrafts" : []
  });

  const fetchMeta = async () => {
    const result = await fetch(getUrl('meta','logbook')).then((res) => res.json()).catch(err => {});
    setMeta(result?.value ?? {});
  }

  useEffect(() => {
    fetchMeta();
  }, []);

  return (
  <Drawer anchor="right" open={open} onClose={onDismiss} PaperProps={{ sx: { width: 380, border: 'none', overflow: 'hidden' }}}>
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
      <Typography variant="subtitle1" sx={{ ml: 1 }}>
        Filters
      </Typography>
    </Stack>

    <Divider />

    <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Aircrafts
        </Typography>
        <FormGroup>
          {
            meta?.aircrafts?.map(aircraft => {
                  return (
                      <FormControlLabel
                          key={aircraft.id}
                          control={
                              <Checkbox
                                  value={aircraft.id}
                                  checked={filter?.aircrafts.includes(aircraft.id)}
                                  onChange={(ev) => {
                                      let aircraft = parseInt(ev.currentTarget.value);

                                      setFilter(prev => {
                                          let newFilter = cloneDeep(prev);

                                          if(newFilter.aircrafts.includes(aircraft))
                                            newFilter.aircrafts = newFilter.aircrafts.filter(e => e !== aircraft);
                                          else
                                            newFilter.aircrafts.push(aircraft);

                                          return newFilter;
                                      })
                                  }}
                              ></Checkbox>
                          }
                          label={aircraft.identification}
                      />
                  )
              })
          }
        </FormGroup>
    </Stack>
    <Box sx={{ p: 3 }}>
        <Stack direction="row" spacing={3}>
            <Button
                fullWidth
                size="small"
                color="inherit"
                variant="outlined"
                onClick={() => {
                    onDismiss();
                }}
            >
                Cancel
            </Button>
            <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={() => {
                  onApply(filter);
                }}
            >
                Save filter
            </Button>
      </Stack>
    </Box>
  </Drawer>
  );
}
