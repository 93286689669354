import { Icon } from '@iconify/react';
import clockOutline from '@iconify/icons-eva/clock-outline';
import personOutline from '@iconify/icons-eva/person-outline';
import globeOutline from '@iconify/icons-eva/globe-outline';
import { Box, Grid, Card, Paper, Typography, CardContent } from '@mui/material';
import { mToHours } from '../../../utils/formatTime';

export function SummaryItem({ site }) {
  const { icon, value, name, description, error } = site; 
  const bColor = (error) ? "primary.lighter" : "error.lighter";

  return (
    <Grid item xs={3}>
      <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', backgroundColor: bColor, border:'0' }}>
        <Box sx={{ mb: 0.5 }}>{icon}</Box>
        <Typography variant="h6">{mToHours(value)}</Typography>
        <Typography variant="h8" sx={{ color: 'text.primary' }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Minimum : {description}
        </Typography>
      </Paper>
    </Grid>
  );
}

export default function SyllabusSummary({summary,minimums}) {

  const values = {
      total: summary ? summary.total : 0,
      dual: summary ? summary.dual : 0,
      solo: summary ? summary.solo : 0,
      cross: summary ? summary.cross : 0
  };

  return (
    <Card mb={10} style={{border:0, padding:0, margin:0}}>
      <CardContent>
        <Grid container spacing={2}>
            <SummaryItem 
              key="1" 
              site={
                {
                  icon: <Icon icon={clockOutline} color="#006097" width={32} height={32} />, 
                  value: values.total, 
                  name: "Total hours", 
                  description: mToHours(minimums.total),
                  error : minimums.total<values.total
                }
              } />
            
            <SummaryItem 
              key="2" 
              error={minimums.dual<values.dual} 
              site={{
                icon: <Box><Icon icon={personOutline} color="#006097" width={32} height={32} /><Icon icon={personOutline} color="#006097" width={32} height={32} /></Box>, 
                value: values.dual, 
                name: "Dual", 
                description: mToHours(minimums.dual), 
                error : minimums.dual<values.dual
              }} 
            />

            <SummaryItem 
              key="3" 
              error={minimums.solo<values.solo} 
              site={{
                icon: <Icon icon={personOutline} color="#006097" width={32} height={32} />, 
                value: values.solo, 
                name: "Solo", 
                description: mToHours(minimums.solo), 
                error : minimums.solo<values.solo
              }} 
            />

            <SummaryItem 
                key="4" 
                error={minimums.cross<values.cross} 
                site={{
                  icon: <Icon icon={globeOutline} color="#006097" width={32} height={32} />, 
                  value: values.cross, 
                  name: "Cross Country", 
                  description: "05H:00M", 
                  error : minimums.cross<values.cross
                }} />

        </Grid>
      </CardContent>
    </Card>
  );
}
