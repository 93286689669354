import React, { useContext } from 'react';
import { Container, Stack } from '@mui/material';
import { AuthContext } from '../Auth';
import Page from '../components/Page';
import SlotCalendar from 'src/components/SlotCalendar';
import Calendar from 'src/components/Calendar';

export default function CalendarPage(props) {
    return (
    <Page title="Flyspace | Calendar page">
        <Container>
            <SlotCalendar/>
        </Container>
    </Page>
  )
}




  
  