import React, { useContext, useState } from 'react';

import {  Box, Stack } from '@mui/material';

import { AuthContext } from '../Auth';

import Page from '../components/Page';
import Logbook from 'src/components/Logbook';
import LogbookFilter  from 'src/components/LogbookFilter';
import LogFormDrawer from 'src/components/_dashboard/logs/LogForm';
import { ReloaderContext } from 'src/Reloader';

export default function LogbookPage(props) {
  const { user } = useContext(AuthContext);
  const { onReload } = useContext(ReloaderContext);

  const [filter,setFilter] = useState(null);

  const handleApplyFilter = (filter) => {
    setFilter(filter);
  }

  return (
    <Page title="Flyspace | Logbook">
       <Box sx={{padding:3}}>
          <Stack direction={"row"} mb={2} spacing={2} justifyContent={"space-between"}>
            { user.admin == 1 && <LogFormDrawer onCancel={() => onReload() }></LogFormDrawer>}
            <LogbookFilter onApply={handleApplyFilter}/> 
          </Stack>
          <Logbook 
            id={user.id} 
            editMode={true} 
            filter={filter}>
          </Logbook> 
        </Box>
    </Page>
  )
}
