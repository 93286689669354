import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/system';


import rentingOutline from '@iconify/icons-eva/arrow-circle-down-outline';

import instructionOutline from '@iconify/icons-eva/award-outline';

import {
  Card,
  Stack,
  Container,
  Typography
} from '@mui/material';

import { Icon } from '@iconify/react';
import { AuthContext } from '../../Auth';

import Page from '../../components/Page';
import Payments from 'src/components/Payments';


// ----------------------------------------------------------------------
export default function StudentsPaymentPage(props) {
  const { user } = useContext(AuthContext);
  
  return (
    <Page title="Flyspace | Payments page">
        <Payments editMode={false} id={user.id} />
    </Page>
  )
}



export function PaymentBalance()
{
  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
  const [ balance, setBalance] = useState(null);
  
  useEffect(() => {
    fetch(getUrl('balance', "payments")) 
      .then((res) => res.json())
      .then(
        (result) => {
            setBalance(result.value);
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  }, []);
  
  return ( 
    <Container style={{marginBottom:'2rem'}}>
    <Stack direction="row" spacing={2} style={{overflow: 'auto',padding:"0px 0px 35px 0px"}}>
      <PaymentCard width={"50%"} key={1} icon={instructionOutline} 
        title="Instruction hours" 
        subtitle={ "Total paid: " + (((balance && balance.payments) ? balance.payments.instruction : 0) + "EUR")} 
        amount={ "Balance: " + ((balance && balance.transactions && balance.payments) ? (balance.transactions.instruction - balance.payments.instruction) + "EUR": "")} 
      />

      <PaymentCard width={"50%"} key={1} icon={instructionOutline} 
          title="Aircraft leasing" 
          subtitle={ "Total paid: " + (((balance && balance.payments) ? balance.payments.lease : 0) + "EUR")} 
          amount={ "Balance: " + ((balance && balance.transactions && balance.payments) ? (balance.transactions.lease - balance.payments.lease)+ "EUR": "")} 
      />

      <PaymentCard width={"50%"} key={2} icon={rentingOutline} 
          title="Theoretical courses" 
          subtitle={ "Total paid: " + (((balance && balance.payments) ? balance.payments.course : 0) + "EUR")} 
          amount={ "Balance: -" } 
      />
    </Stack>
  </Container>
  ); 
}

const RootStyle = styled(Card)(({ theme }) => {

  return {
    borderWidth: "1px",
    borderColor: theme.palette.grey["300"],
    borderStyle: "solid",
    width:"33%",
    minWidth:'250px',
    padding:10,
    boxShadow: "none"
  }

});

const IconPlus = styled('div')(({ theme }) => ({
  color: theme.palette.success.dark
}));

const IconMinus = styled('div')(({ theme }) => ({
  color: theme.palette.error.dark
}));

export function PaymentCard({icon,title, subtitle, amount, width}) {
  
  return (
    <RootStyle style={{width:(width ? width: '33%')}}>
      <Stack direction="row" alignItems="center">
          <IconPlus><Icon icon={icon} width={54} height={54} /></IconPlus>
        
          <Stack direction="column" ml={5}>
            <Typography variant="body2" fontWeight="bold">{title}</Typography>
            {
              subtitle && <Typography variant="subtitle">{subtitle}</Typography>
            }
            <Typography variant="h4"> 
              {amount}
            </Typography>
          </Stack>
      </Stack>
    </RootStyle>
  );
}