import { Button } from "@mui/material";
import AppDrawer from "./AppDrawer";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/Auth";


export default function ShopDrawer() {
    const { user } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(open == true) {
            const script = document.createElement('script');
            script.src = "//flyaround.ro/shop/dist/main.js";
            script.async = true;
            script.setAttribute('email', user.email);

            document.body.appendChild(script);
        
            return () => {
            document.body.removeChild(script);
            }
        }
      }, [open]);

    return (
        <>
            <Button variant="contained" onClick={() => {setOpen(true)}}>Shop</Button>
            <AppDrawer height={100} open={open} onClose={() => { setOpen(false) }}>
                <div id="flyaround-shop-root" data-email={user.email}></div>
            </AppDrawer>
        </>
    )
}