import { merge } from 'lodash';
import Card, { TableHead } from './Card';
import Lists from './Lists';
import Paper from './Paper';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Typography from './Typography';
import IconButton from './IconButton';
import Autocomplete from './Autocomplete';
import Chip from './Chip';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    TableHead(theme),
    Card(theme),
    Lists(theme),
    Paper(theme),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Typography(theme),
    IconButton(theme),
    Chip(theme),
    Autocomplete(theme)
  );
}
