import React, { useContext, useState, useEffect } from 'react';

import {
  Card,
  Table,
  TableHead,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  Tabs,
  Tab, 
  Link,
  Badge
} from '@mui/material';
import { AuthContext } from '../Auth';

import { LoadingButton } from '@mui/lab';
import { DocumentsMoreMenu } from './_dashboard/user/UserMoreMenu';


export default function Documents({id, editMode}) {

    const random = () => {
        return Math.floor(Math.random() * 100000);
    }

    const { isLoggedIn, getUrl,user } = useContext(AuthContext);
    const [ selectedTab, setSelectedTab] = useState(0);
    const [ documents, setDocuments] = useState(null);
    const [ uploading , setUploading] = useState(false);
    const [ refresher, setRefresher] = useState(random());
    const [ owner, setOwner] = useState(null);
    const [ counters, setCounters] = useState({
        "AGREEMENT": 0,
        "STUDENT_DOCUMENT": 0
    });

   
    const TABLE_HEAD = [
        { id: 'id', label: 'Id', alignRight: false },
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'last_edit', label: 'Uploaded by', alignRight: false },
        { id: 'document', label: 'Document', alignRight: false }
    ];

    useEffect(() => {
        let type = "AGREEMENT";
        switch(selectedTab)
        {
            case 0: 
                type = "AGREEMENT";
                break;
            case 1: 
                type = "STUDENT_DOCUMENT";
            break;
            case 2:
                type = "INVOICE"; 
            break;
            default: 
                type = "AGREEMENT";
        }
        fetch(getUrl('documents') + "&type=" + type + "&id=" + id) 
          .then((res) => res.json())
          .then(
            (result) => {
                setOwner(result.value.owner);
                setDocuments(result.value.documents);
            },
            (error) => {
              throw(error)
            }
          ).finally(() => { 
          });
        
      }, [selectedTab, refresher,id]);

      useEffect(() => {
        fetch(getUrl('documents/counters') + "&id=" + id) 
          .then((res) => res.json())
          .then(
            (result) => {
                setCounters(result.value);
            },
            (error) => {
              throw(error)
            }
          ).finally(() => { 
          });
      }, [refresher]);

    const input = document.getElementById('upload-document');

    const upload = (file, doc) => {
        var data = new FormData();
        data.append('file', file);
        
        fetch(getUrl("documents") + "&docType=" + doc.id + "&uid=" + id, { // Your POST endpoint
           method: 'POST',
           body: data // This is your file object
        }).then((response) => response.json()).
            then((result) => { 
               setUploading(false);
               setRefresher(random());
            } // Handle the success response object
        ).catch(
           error => console.log(error) // Handle the error response object
        );
     };

     const request = (docTypeId, cancel) => {
        
        fetch(getUrl("documents/request") + "&docType=" + docTypeId + "&uid=" + id +"&cancel=" + cancel, { // Your POST endpoint
           method: 'POST',
           body: new FormData() // This is your file object
        }).then((response) => response.json()).
            then((result) => { 
                setRefresher(random());
            } 
        ).catch(
           error => console.log(error) // Handle the error response object
        );

     }

     const onSelectFile = (ev, doc) => {
         setUploading(doc.id); 
         upload(ev.currentTarget.files[0], doc);
     }

     const onMoreButton = (action, objKey) => {
         
        switch(action)
        {
            case "request":
                request(objKey, 0);
                break;  
            case "cancel":
                request(objKey, 1);
                break;
            default: 

        }
        
        
     }


    return (
        <Container>
            <Card sx={{ width: '100%' }}>
                { 
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={(ev,val) => { setSelectedTab(val) }} aria-label="">
                            {
                                counters.AGREEMENT > 0 && <Tab label={<><Badge style={{position:'absolute',top:12, right:12}} badgeContent={counters.AGREEMENT} color="error"></Badge>Agreements</>} />
                            }
                            {
                                counters.AGREEMENT === 0 && <Tab label={<>Agreements </>} />
                            }
                            {
                                counters.STUDENT_DOCUMENT > 0 && <Tab label={<><Badge style={{position:'absolute',top:12, right:12}} badgeContent={counters.STUDENT_DOCUMENT} color="error"></Badge>Student documents </>} />
                            }
                            {
                                counters.STUDENT_DOCUMENT === 0 && <Tab label={<>Student documents </>} />
                            }
                        </Tabs>
                    </Box>
                }
                <TableContainer sx={{ width: '100%'}}>
                    <Table>
                    <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.alignRight ? 'right' : 'left'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                    ))
                                    }
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    documents && documents.map((doc, index) => {
                                        const draft = (doc.draft != null) ? getUrl("documents/download") + "&id=" + doc.draft : null;
                                        const alternate = (doc.alternate_draft != null) ? getUrl("documents/download") + "&id=" + doc.alternate_draft : null;
                                        const href = (doc.file && doc.file.document) ? getUrl("documents/download") + "&id=" + doc.file.document : null;
                                        
                                        return (
                                            <TableRow  key={doc.id} tabIndex={-1} aria-checked={false}>
                                                <TableCell>
                                                    {
                                                        doc.file && doc.file.requested === 1 && <Badge badgeContent={"!"} color="error"></Badge>
                                                    }
                                                    {
                                                        (!doc.file || doc.file.requested === 0) && <>{doc.id}</>
                                                    }
                                                </TableCell>
                                                <TableCell style={{width:'50%'}}>
                                                    <Typography>
                                                        {doc.name.toUpperCase()}
                                                    </Typography>
                                                    <Stack direction="row" spacing={2}>
                                                        {
                                                            draft && 
                                                                <Link target="_blank" href={draft}>Draft 1</Link>
                                                        }
                                                        {
                                                            alternate && 
                                                                <Link target="_blank" href={alternate}>Draft 2</Link>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        doc.file && doc.file.uploader && 
                                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                                <Avatar alt={doc.file.uploader_instance.name} src={doc.file.uploader_instance.picture}  style={{ height: '32px', width: '32px' }}/>
                                                                <Typography textAlign="center" variant="body2">
                                                                    {doc.file.uploader_instance.name}
                                                                </Typography>
                                                            </Stack>
                                                    }
                                                </TableCell>
                                                <TableCell width={'30%'}>
                                                    <Stack direction="row" spacing={2}>
                                                        <Button target="_blank" href={href} disabled={!doc.file || !doc.file.document} variant="outlined" size="small">View document</Button>
                                                        
                                                        
                                                        { 
                                                        editMode && <label htmlFor={"upload-document-" + doc.id}>
                                                            <input onChange={(ev) => { onSelectFile(ev, doc); }} 
                                                                style={{ display: 'none' }}
                                                                accept="application/pdf"
                                                                id={"upload-document-" + doc.id}
                                                                name="upload-document"
                                                                type="file"
                                                            />
                                                                <LoadingButton loading={uploading === doc.id} variant="outlined" size="small" component="span">
                                                                    Upload document
                                                                </LoadingButton>
                                                            </label>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                    
                                                    editMode && <DocumentsMoreMenu onClick={onMoreButton} objKey={doc.id} requested={doc.file && doc.file.requested === 1}/>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }     
                                {
                                    (!documents || documents.length === 0)  && 
                                    <TableRow>
                                        <TableCell colSpan={6} style={{textAlign:"center"}}>No documents</TableCell>
                                    </TableRow>
                                } 
                            </TableBody>           
                        <TableBody>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Container>
        
    );
}