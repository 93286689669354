// material
import { Alert,FormControlLabel,Switch, TextField, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, Autocomplete, FormGroup, Stack, InputLabel, FormHelperText, FormControl } from '@mui/material';
// utils
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AuthContext } from 'src/Auth';

import {  LoadingButton } from '@mui/lab';
import { ReloaderContext } from '../../../Reloader';
import AppDrawer from 'src/components/AppDrawer';
import { DatePicker, DateTimePicker, DigitalClock, LocalizationProvider, MobileDateTimePicker, MultiSectionDigitalClock, StaticDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSnackbar } from 'src/hooks/useSnackbar';


export default function LogFormDrawer({onCancel, onChange, defaults}) {
  const snackbar = useSnackbar();

  const { getUrl } = useContext(AuthContext);

  const [open,setOpen] = useState(false);
  
  const [saving, setSaving] = useState(false);

  const [loading, setLoading] = useState(null);

  const [state, setState] = useState({
    aircraft: defaults?.aircraft ?? null,
    type: defaults?.type ?? "INSTRUCTION",
    pic: defaults?.pic ?? null, 
    instructor: null, 
    lease: null, 
    start: moment(), 
    end: moment(),
    landings: 1, 
    warmup: 4, 
    agreement: null,
    from: {},
    to: {}, 
    paid_flight: 1
  });
  
  const [values, setValues] = useState({ 
    aircrafts: [],
    locations: [],
    pilots: [], 
    exercises: [],
  });
  
  const fetchData = async (url, key) => {
    const result = await fetch(url);
    const _values = (await result.json()).value;
    setValues(prevValues => ({...prevValues, [key]: _values}));
  }
  
  const loadData = async () => {
    setLoading(true);
    await Promise.all([
      fetchData(getUrl('aircrafts'), 'aircrafts'),
      fetchData(getUrl('locations'), 'locations'),
      fetchData(getUrl('pilots') + "&roles[]=instructor&&roles[]=student&roles[]=pilot", 'pilots'),
      fetchData(getUrl('exercises'), 'exercises')
    ]);
    setLoading(false);
  }
  
  useEffect(() => {
    open && loadData();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  const handleSave = async () => {
    const saveObj = {
      ...state,
      instructor: ["INSTRUCTION", "DUAL"].includes(state.type) ? state.instructor : null,
      lease: state.type === "LEASE" ? state.lease : null,
      type: state.type === "LEASE" ? "SOLO" : state.type,
    };

    Object.entries(saveObj).forEach(([key, value]) => {
      if (value && typeof value === 'object') {
        if ('id' in value) {
          saveObj[key] = value.id;
        } else if (moment.isMoment(value)) {
          saveObj[key] = value.unix() + value.utcOffset() * 60
        }
      }
    });

    //check if all the fields are filled
    if(saveObj.start + saveObj.warmup * 60 >= saveObj.end) {
      snackbar.openError("Start date cannot be greater than end date");
      return;
    } 
    
    if(!saveObj.pic) {
      snackbar.openError("PIC is required");
      return;
    }
    if(saveObj.type === "INSTRUCTION" && !saveObj.instructor) {
      snackbar.openError("Instructor is required");
      return;
    }
    if(saveObj.type == "INSRUCTION" && !saveObj.exercise) {
      snackbar.openError("Exercise is required");
      return;
    }
    if(saveObj.type === "LEASE" && !saveObj.lease) {
      snackbar.openError("Lease person is required");
      return;
    }
    if(!saveObj.aircraft) {
      snackbar.openError("Aircraft is required");
      return;
    }

    const formData = new FormData();
    Object.entries(saveObj).forEach(([key, value]) => formData.append(key, value));
  
    setLoading(true);   
    
    const response = await fetch(getUrl('index', 'logbook'), {
      method: 'post',
      body: formData
    });
    const data = await response.json();
    
    setLoading(false);
    handleClose();

    return false;
  };

  
  return (
    <>
      <Button variant={"contained"} onClick={() => setOpen(true)}>Log a flight</Button>
      <AppDrawer loading={loading} title={"Log a flight"} height={100} open={open} onClose={() => setOpen(false)}>
            <Stack spacing={3} direction="row" flexWrap="wrap" mt={3} justifyContent={"space-between"}>
                <Stack spacing={3} width={"30%"}>
                  <Stack direction={"row"} spacing={2}>
                    <TextField disabled={loading} label="Warmup minutes"  onChange={(el)=> setState({...state,warmup: el.target.value})} value={state.warmup}></TextField> 
                    <TextField disabled={loading} label="Landings"  onChange={(el)=> setState({...state,landings: el.target.value})} value={state.landings}></TextField> 
                  </Stack>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          disabled={loading}
                          label="Block start"
                          ampm={false}
                          onChange={(val)=> { 
                            let newState = {...state, start: val};
                            if(val > state.end) {
                              newState.end = val;
                            }
                            setState(newState);
                          }}
                          value={state.start ?? moment()}
                          minutesStep={1}
                          renderInput={(params) => <TextField {...params} />}
                        />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          disabled={loading}
                          label="Block end"
                          ampm={false}
                          onChange={(val)=> { setState({...state, end: val}) }}
                          value={state.end ?? moment()}
                          minutesStep={1}
                          renderInput={(params) => <TextField {...params} />}
                        />
                  </LocalizationProvider>

                  
                </Stack>


                <Stack spacing={3} width={"30%"}>
                  <Autocomplete 
                      disablePortal
                      disabled={loading}
                      getOptionLabel={(option) => option.label || ""}
                      id="aircraft"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={values?.aircrafts?.map((el) => { return {label: el.identification, id: el.id}}) ?? []}
                      value={state.aircraft ?? null}
                      onChange={(ev,val) =>  {  setState({...state, aircraft:val})}}
                      renderInput={(params) => <TextField {...params} label="Aircraft" />}
                      />
                  <Autocomplete style={{overflow:'visible !important'}}
                      disablePortal
                      disabled={loading}
                      id="location_from"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={values?.locations?.map((el) => { return {label: el.icao, id: el.id}}) ?? []}
                      value={state.from ?? null}
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(ev,val) =>  {  setState({...state, from:val})}}
                      renderInput={(params) => <TextField {...params} label="From" />}
                      />
                  <Autocomplete style={{overflow:'visible !important'}}
                      disablePortal
                      disabled={loading}
                      id="location_to"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={values?.locations?.map((el) => { return {label: el.icao, id: el.id}}) ?? []} 
                      value={state.to ?? null}
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(ev,val) =>  {  setState({...state, to: val})}}
                      renderInput={(params) => <TextField {...params} label="To" />}
                      />
                </Stack>

                <Stack spacing={3} width={"30%"}>
                <Select
                    labelId="type_label"
                    id="type"
                    disabled={loading}
                    value={state.type ?? ""}
                    onChange={(ev) => {
                      const _newState = {...state};
                      _newState.type = ev.target.value;
                      _newState.instructor = null;
                      _newState.pic = null;
                      _newState.lease = null;
                      _newState.exercise = null;
                      
                      setState(_newState)
                    }}
                  > 
                    <MenuItem value={"INSTRUCTION"}>INSTRUCTION</MenuItem>
                    <MenuItem value={"SOLO"}>SOLO</MenuItem>
                    <MenuItem value={"LEASE"}>LEASE</MenuItem>
                </Select>

                <Autocomplete 
                      id="pilot"
                      disabled={loading}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={values?.pilots?.map((el) => { return {label: el.name, id: el.id}}) ?? []}
                      value={state?.pic ?? null}
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(ev,val) =>  { 
                        setState({...state,pic: val})
                      }}
                      renderInput={(params) => <TextField {...params} label="Pilot" />}
                  />

                  {
                    (state.type === "INSTRUCTION") &&
                    <>
                      <Autocomplete 
                          disabled={loading}
                          id="instructor"
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={values?.pilots?.map((el) => { return {label: el.name, id: el.id}}) ?? []}
                          value={state.instructor ?? null}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(ev,val) =>  {  setState({...state,instructor:val})}}
                          renderInput={(params) => <TextField {...params} label="Instructor" />}
                          />
                      
                      <Autocomplete 
                          disabled={loading}
                          id="exercise"
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={values?.exercises?.map((el) => { return {label: el.ident + " - " + el.name, id: el.id}}) ?? []}
                          value={state.exercise ?? null}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(ev,val) =>  {  setState({...state,exercise: val})}}
                          renderInput={(params) => <TextField {...params} label="Exercise" />}
                          />
                      </>
                  }
                  
                  
                  
                  {
                    state.type === "LEASE" &&
                      <Autocomplete 
                      disabled={loading}
                      disablePortal
                      id="lease"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={values.pilots.map((el) => { return {label: el.name, id: el.id}})}
                      value={state.lease ?? null}
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(ev,val) =>  {  setState({...state,lease: val})}}
                      renderInput={(params) => <TextField {...params} label="Leased by" />}
                      />
                  }

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.paid_flight}
                          onChange={(event) => {
                            setState({ ...state, paid_flight: event.target.checked });
                          }}
                          name="paid_flight"
                          color="primary"
                        />
                      }
                      label={state.paid_flight ? "Paid flight" : "Free of charge flight"}
                    />
                  </FormControl>


                </Stack>
            </Stack>
            <Stack mt={3} justifyContent={"center"}>
                <LoadingButton onClick={handleSave} loading={saving} variant='contained'>Submit log</LoadingButton>
            </Stack>
      </AppDrawer>
    </>
  );
}