import { useContext } from 'react';

import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';

import { AuthContext } from './Auth';
import PreloaderLayout from './layouts/PreloaderLayout';

import AdminReportsPage from './pages/admin/AdminReportsPage';
import AdminLogbookPage from './pages/admin/AdminLogbookPage';
import AdminSyllabusPage from './pages/admin/AdminSyllabusPage';

import AvailabilityPage from './pages/AvailabilityPage';


import StudentLogbookPage from './pages/student/StudentLogbookPage';

import LogbookPage from './pages/LogbookPage';
import StudentsPaymentPage from './pages/student/StudentsPaymentPage';
import AdminDocumentsPage from './pages/admin/AdminDocumentsPage';

import StudentDocumentsPage from './pages/student/StudentDocumentsPage';
import AdminPaymentsPage from './pages/admin/AdminPaymentsPage';
import AdminFormsPage from './pages/admin/AdminForms';
import CalendarPage from './pages/CalendarPage';
import SharedAccess from './pages/SharedAccess';
import AdminDashboard from './pages/admin/AdminDashboard';
import DashboardPage from './pages/DashboardPage';
import AdminFuelManagement from './pages/admin/AdminFuelManagement';
import TrainingsPage from './pages/TrainingsPage';


// ----------------------------------------------------------------------
export default function Router(props) {
  const { isLoggedIn, loading, user } = useContext(AuthContext);
  const location = useLocation();
  
  const preloaderRoutes = [
    {
      path: '/',
      element: <PreloaderLayout />,
      children: [
        { path: 'shared/:perma_link', element: <SharedAccess /> }
      ]
    }
  ];
  
  const adminRoutes = [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'dashboard/:user_id', element: <DashboardPage /> },
        { path: 'fuel', element: <AdminFuelManagement /> },
        { path: 'forms', element: <AdminFormsPage /> },
        { path: 'reports', element: <AdminReportsPage /> },
        { path: 'students/syllabus/:id', element: <AdminSyllabusPage/> },
        { path: 'students/flights/:id', element: <AdminLogbookPage/> },
        { path: 'students/documents/:id', element: <AdminDocumentsPage/> },
        { path: 'flights', element: <LogbookPage/> },
        { path: 'payments', element: <AdminPaymentsPage/> },
        { path: 'calendar', element: <CalendarPage/> },
        { path: '404', element: <NotFound /> },

        { path: '/', element: <Navigate to={"/dashboard"} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];

  const studentRoutes = [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard/:user_id', element: <DashboardPage /> },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'documents', element: <StudentDocumentsPage/> },
        { path: 'payments', element: <StudentsPaymentPage/> },
        { path: 'trainings', element: <TrainingsPage/> },
        { path: 'trainings/:user_id', element: <TrainingsPage/> },
        { path: 'trainings/:user_id/training/:training_id/:widget', element: <TrainingsPage/> },
        { path: 'flights', element: <StudentLogbookPage/> },
        { path: 'calendar', element: <CalendarPage/> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];

  const pilotRoutes = [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard/:user_id', element: <DashboardPage /> },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'payments', element: <StudentsPaymentPage/> },
        { path: 'flights', element: <StudentLogbookPage/> },
        { path: 'calendar', element: <AvailabilityPage/> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ]

  const loginRoutes = [
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> }
      ]
    }
  ];

  let routes = preloaderRoutes;

  if(!location.pathname.includes("/shared/") && loading === false)
  {
      if(isLoggedIn && user && user.type === "STUDENT")
      {
        routes = studentRoutes;

      }else if(isLoggedIn && user && user.type === "PILOT")
      {
        routes = pilotRoutes;
      }else if (isLoggedIn)
      {
        routes = adminRoutes;
      }else {
        routes = loginRoutes;
      }
  }
  
  return useRoutes(routes);
}
