import React, { useContext, useState } from 'react';

import { Link as useParams } from 'react-router-dom';

import { Stack, Container, Typography, Link, Breadcrumbs } from '@mui/material';
import { AuthContext } from '../../Auth';

import Page from '../../components/Page';
import LogForm from 'src/components/_dashboard/logs/LogForm';
import { LoadingButton } from '@mui/lab';
import Logbook from 'src/components/Logbook';


// ----------------------------------------------------------------------


export default function AdminLogbookPage(props) {
  const { isLoggedIn, getUrl } = useContext(AuthContext);
  const [ state, setState] = useState({isLoading: true});
  const [ selectedItem, setSelectedItem] = useState(null);
  const [ random, setRandom] = useState(Math.random()); 
  const [logform, setLogform] = useState(null);

  const [page, setPage] = useState(0);

  let { id } = useParams();
  
  const handleOnChange = (ev, log) => {
    setRandom(Math.random());
  }

  const downloadLogbook = () => {
    window.open(getUrl("print/logbook") + "&id=" + id, "_blank");
  }
  
  return (
    <Page title="Flyspace | Admin Logbook">
      {
        logform !== null && <LogForm exercise={logform.ex} pilot={logform.pilot} isOpen={logform !== null} onChange={handleOnChange} onCancel={() => setLogform(null)}/>
      }
        <Container>
            <Stack direction="row">
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link color="inherit" href="/" >
                        Students Report
                    </Link>
                    <Typography color="textPrimary">Logbook</Typography>
                </Breadcrumbs>
                <LoadingButton style={{height: '2rem', marginLeft:'auto'}} onClick={downloadLogbook}>
                    Download logbook (PDF)
                </LoadingButton>
            </Stack>
        </Container>
      <Logbook id={id}></Logbook> 
    </Page>
  )
 

  function handleOpenPanel(log) {
    setSelectedItem(log)
  }
}
