import React from 'react';
import Page from '../../components/Page';
import AdminPayments from 'src/components/admin/AdminPayments';

export default function AdminPaymentsPage(props) {
  return (
    <Page title="Flyspace | Admin payments">
      <AdminPayments/>
    </Page>
  );
}
