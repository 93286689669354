import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

// material
import { Box, Stack, AppBar, Toolbar, IconButton, Autocomplete, TextField, Avatar, Typography } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/Auth';
import { useNavigate } from 'react-router-dom';

import { styled, alpha } from '@mui/system';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
         
          <UserSearch width={"90%"}></UserSearch>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
            <AccountPopover />
          </Stack>
         
      </ToolbarStyle>
      
    </RootStyle>
  );
}


function UserSearch({width}){
  const classes = {
    root: {
      '& .MuiFilledInput-root': {
        border: '0px',
        borderRadius: '10px',
        backgroundColor:'#f9f9f9'
      },
      '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
        borderColor:'#f1f1f1'
      }
    },
  };

  const navigate = useNavigate();
  
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState(null);

  const {getUrl} = useContext(AuthContext);
  

  useEffect(()=>{
    fetch(getUrl('search', 'members')) 
      .then((res) => res.json())
      .then(
        (result) => {
            setMembers(result.value);
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  },[]);
 
  return (
    <Autocomplete 
      
      id="people_select"
      options={members}
      value={selected}
      style={{width:width}}
      autoHighlight
      getOptionLabel={(option) => option.name}
      
      onChange={(event, option) => {
        setSelected(null);
        navigate('/dashboard/' + option.id);
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}  {...props}>
          <Avatar src={option.picture} style={{width:30, height:30, marginRight:10}}></Avatar>
          <Typography variant="body2"> {option.name}</Typography>
        </Box>
      )}

      renderInput={(params) => (
        <TextField
          {...params}
          variant={"filled"}
          label="Search user"
          
          inputProps={{
            ...params.inputProps,
            autoComplete: 'people_select'
          }}
        />
    )}
  />
);
}
