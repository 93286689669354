import PropTypes from 'prop-types';
import { useEffect, useContext,useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider, Card } from '@mui/material';

// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';

import { AuthContext } from '../../Auth';

// ----------------------------------------------------------------------
import { Icon } from '@iconify/react';

import peopleFill from '@iconify/icons-eva/people-fill';
import awardFill from '@iconify/icons-eva/award-outline';

import bookFill from '@iconify/icons-eva/book-open-outline';

import menuOutline from '@iconify/icons-eva/menu-outline';
import cardOutline from '@iconify/icons-eva/credit-card-outline';
import calendarOutline from '@iconify/icons-eva/calendar-outline';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import carOutline from '@iconify/icons-eva/car-outline';

import LogForm from 'src/components/_dashboard/logs/LogForm';
import ShopDrawer from 'src/components/ShopDrawer';




const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[0]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let sidebarConfig = [];

  switch(user.type )
  {
    case "STUDENT":
    case "PILOT":
   
      sidebarConfig = [
        {
          title: 'Dashboard', 
          path: '/dashboard',
          icon: <Icon icon={menuOutline} width={22} height={22} />
        },
        {
          title: 'Trainings', 
          path: '/trainings',
          icon: <Icon icon={awardFill} width={22} height={22} />
        },
        {
          title: 'Flights',
          path: '/flights',
          icon: <Icon icon={bookFill} width={22} height={22} />
        },
        {
          title: 'Payments',
          path: '/payments',
          icon: <Icon icon={cardOutline} width={22} height={22} />
        },
        {
          title: 'Documents',
          path: '/documents',
          icon: <Icon icon={archiveOutline} width={22} height={22} />
        },
        {
          title: 'Calendar',
          path: '/calendar',
          icon: <Icon icon={cardOutline} width={22} height={22} />
        }
      ];
    break;
    default: 
      sidebarConfig = []
  }

  //if it is admin , overwrtie 
  if(user.admin)
  {
    sidebarConfig = [
      {
        title: 'Dashboard', 
        path: '/dashboard',
        icon: <Icon icon={menuOutline} width={22} height={22} />
      },
      {
        title: 'Flights',
        path: '/flights',
        icon: <Icon icon={bookFill} width={22} height={22} />,
      },
      {
        title: 'Payments',
        path: '/payments',
        icon: <Icon icon={cardOutline} width={22} height={22} />,
      },
      {
        title: 'Calendar',
        path: '/calendar',
        icon: <Icon icon={calendarOutline} width={22} height={22} />,
      },
      {
        title: 'Management',
        path: '/reports',
        icon: <Icon icon={peopleFill} width={22} height={22} />
      }
    ];
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={user.picture} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <Box>
        <NavSection navConfig={sidebarConfig} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{textAlign:"center", mt:3}}>
        { user?.admin == 0 && <ShopDrawer></ShopDrawer> }
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
