import React, { useContext, useState, useEffect } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { AuthContext } from '../Auth';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';

// material
import {
  Box,
  Card,
  CardHeader,
  Typography,
  FormControlLabel,
  FormControl,
  Stack,
  Avatar,
  TextField,
  Switch,
  Select, 
  MenuItem,
  Chip,
  Tooltip
} from '@mui/material';

import { GoalsMoreMenu } from './_dashboard/user/UserMoreMenu';

import { DatePicker, LoadingButton } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PeoplePicker from './PeoplePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function Goals() {
  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
 
  const theme = useTheme();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetch(getUrl('goals', 'community')) 
      .then((res) => res.json())
      .then(
        (result) => {
          if(result.error === false)
            setTasks(result.value);
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  }, []);

  
  return (
    <Stack>
      <Card>
        <CardHeader title="Community goals/tasks" />
        <Box sx={{ px: 3, py: 1 }}>
              {tasks.map((task) => {
                const date  = moment.unix(task.date).format("DD/MM/YYYY");
                const joined = task.team.find(t => t.id === user.id) !== undefined;
                 
                return (
               <Stack direction="row" justifyContent="space-between" sx={{ py: 2 }}>
                    <FormControlLabel
                      control={
                        <Box style={{width:'4px', height:'100%', backgroundColor:theme.palette.primary.main, marginRight:'10px'}}></Box>
                      }
                      label={
                        <Stack direction="column">
                          <Stack direction="row" spacing={2} sx={{py: 0.75}}>
                            <Typography variant="body">
                              {task.title} 
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2}> 
                              <Chip label={date} size="small" style={{fontWeight:'bold', width:'max-content'}}/>
                              <Chip label={task.tag} size="small" style={{fontWeight:'bold', width:'max-content'}}/>
                              <Stack direction="row" spacing={0.5}> 
                                {
                                  task.team.map(user => {
                                    return (
                                      <Tooltip title={user.name}>
                                        <Avatar 
                                            alt={user.name} 
                                            src={user.picture} 
                                            style={{borderRadius: "24px", width:"24px", height:"24px"}} 
                                        />
                                      </Tooltip>
                                    );
                                  })
                                }
                              </Stack>
                          </Stack>
                         
                        </Stack>
                      }
                    />
                    <Box>
                     <GoalsMoreMenu joined={joined} onClick={(action) => alert(action)}></GoalsMoreMenu>
                    </Box>
             </Stack>
              );
            })}
        </Box>
      </Card>
    </Stack>
  );
}


const validationSchema = yup.object({
  title: yup
    .string('Enter the title of the event/goal')
    .min(4, 'The title should be at least 4 chars long')
    .required('The title is required'),
  private: yup
    .boolean("This value should be boolean"),
  date: yup
    .date(),
  document: yup
    .string("Enter a document id.")
    .min(25, 'The document id is minimum 25 chars long')
    .nullable()
});

const textEditor = createTheme({});

export function GoalsForm() {
  const { isLoggedIn, getUrl, user } = useContext(AuthContext);
  const [ loadingTags, setLoadingTags] = useState(true);
  const [ tags, setTags] = useState([]);
  const [ loading, setLoading] = useState(false);
 
  const formik = useFormik({
    initialValues: {
      title: '',
      type: 'GOAL',
      private: false,
      date: moment().unix(),
      team: [user.id],
      document: '',
      description: '',
      tag: 'GENERAL',
      status: 'ACTIVE'
    },
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm}) => {
        const formData  = new FormData();

        Object.keys(values).forEach(fieldName => {
          console.log(fieldName, values[fieldName]);
          formData.append(fieldName, values[fieldName]);
        });

        setLoading(true);
        fetch(getUrl('goals', 'community'), {
          method: 'post',
          body: formData
        }).then((res) => res.json()).then((response) => {
              if (response && response.value) {
                setLoading(false);
                resetForm();
              }
        });
    },
    onReset: () => {

    }
  });



  
  useEffect(() => {
    fetch(getUrl('tags', 'community')) 
      .then((res) => res.json())
      .then(
        (result) => {
            setLoadingTags(false);
            setTags(result.value);
        },
        (error) => {
          throw(error)
        }
      ).finally(() => { 
      });
  }, []);


  return (
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <FormControl>
            <TextField
              fullWidth
              id="title"
              name="title"
              label="Title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </FormControl>

          
          <FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    id="date"
                    label="Goal end date"
                    ampm={false}
                    onChange={(val)=>{
                      formik.setFieldValue('date', moment(val).startOf('day').unix());
                    }}
                    value={
                      moment.unix(formik.values.date)
                    }
                    renderInput={(params) => <TextField {...params} />}
                    />
                 </LocalizationProvider>
          </FormControl>
          <FormControl>
              <TextField
                  fullWidth
                  id="document"
                  name="document"
                  label="Document"
                  value={formik.values.document}
                  onChange={formik.handleChange}
                  error={formik.touched.document && Boolean(formik.errors.document)}
                  helperText={formik.touched.document && formik.errors.document}
                />
          </FormControl>
          <FormControl>
                <Select
                labelId="tag"
                id="tag"
                value={formik.values.tag}
                label="Tag the goal"
                onChange={(el) => {
                  formik.setFieldValue('tag', el.target.value);
                }}
              >
                {
                  tags.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)
                }
              </Select>
          </FormControl>
          <FormControl>
              <PeoplePicker
                value={formik.values.team}
                onChange={(selected) => {
                  let team = [];
                  selected.forEach(element => {
                    team.push(element.id);
                  });

                  formik.setFieldValue('team', team);
                }}
              ></PeoplePicker>
          </FormControl>
          <FormControl>
            <Card style={{minHeight:'300px', padding:10}}>
                {
                  /*               
                  <ThemeProvider theme={textEditor}>
                    <MUIRichTextEditor 
                        onChange={(state) => {
                          formik.setFieldValue('description',stateToHTML(state.getCurrentContent()));
                        }}
                        value={""}
                        readOnly={false}
                        controls={["title", "bold", 'italic', 'underline', 'link', 'bulletList', 'highlight', "my-block", "my-style", "clear", "my-callback", "clear-callback", 'my-list']}
                        label="Write description ..." 
                    />
                  </ThemeProvider>
                  */
                }
            </Card>
          </FormControl>

          <FormControl>
            <FormControlLabel control={
               <Switch
               id="private" 
               onChange={formik.handleChange} 
               checked={formik.values.private === true} 
             />
            } label="Private goal" />
          </FormControl>



          <FormControl>
          <LoadingButton color="primary" variant="contained" fullWidth type="submit" loading={loading}>
            Save
          </LoadingButton>
          </FormControl>
       </Stack>
       
      </form>
 
  );
}
