import React, { useContext, useState } from 'react';

import {
  Stack,
  Container,
  Typography,
  Link,
  Breadcrumbs,
} from '@mui/material';

import { AuthContext } from '../../Auth';
import Page from '../../components/Page';
import Syllabus from 'src/components/Syllabus';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const TABLE_HEAD = [
  { id: 'aircraft', label: 'Aircraft', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'from', label: 'From', alignRight: false },
  { id: 'to', label: 'To', alignRight: false },
  { id: 'flight_time', label: 'Flight time', alignRight: false },
  { id: 'landings', label: 'Flights/Landings', alignRight: false },
  { id: 'instructor', label: 'Instructor', alignRight: false }
];

export default function AdminSyllabusPage(props) {
  const { getUrl, user } = useContext(AuthContext);

  let { id } = useParams();
  
  const downloadSyllabus = () => {
    window.open(getUrl("print") + "&id=" + id, "_blank");
  };
  
  return (
    <Page title="Flyspace | Syllabus">
      <Container>
        <Stack direction="row">
          <Breadcrumbs aria-label="breadcrumb" mb={5}>
            <Link color="inherit" href="/" >
              Students Report
            </Link>
            <Typography color="textPrimary">Syllabus</Typography>
          </Breadcrumbs>
          <LoadingButton style={{height: '2rem', marginLeft:'auto'}} onClick={downloadSyllabus}>
            Download syllabus (PDF)
          </LoadingButton>
        </Stack>
       
      </Container>
      <Syllabus id={id} editMode={user.admin === 1}/>
    </Page>
  );
}
