import React, { useState, useEffect, useContext } from 'react';

import Cookies from 'js-cookie';

export const AuthContext = React.createContext(null);
export const API_URL = "//api-flyspace.flyaround.ro/";

export default function AuthContextProvider({ children, access_token }) {
  const [state, setState] = useState({isLoggedIn: false, user: null, isLoading: true});
  //const token = access_token || Cookies.get('fs_access_token');
  const token = access_token || localStorage.getItem("flyspace_jwt");
  
  useEffect(() => {
    if(!token) return setState({user: null, isLoggedIn: false, isLoading:false});
  
    fetch(`${API_URL}auth/member?access_token=${token}`)
      .then(res => res.json())
      .then(
        result => setState({...state, user: result.value, isLoggedIn: true, isLoading: false}),
        error => setState({user: null, isLoggedIn: false, isLoading:false, requested_user:null})
      );
  }, []);
  

  return (
    <AuthContext.Provider
      value={{
        loading: state.isLoading,
        isLoggedIn: (state) ? state.isLoggedIn : false,
        user: (state && state.user) ? state.user : null,
        getUrl: (path, source = 'api') => {
          const url = `${API_URL}${source}` + ((source !== '') ? "/" : "");
          return `${url + path}?access_token=${localStorage.getItem("flyspace_jwt")}`;
        },
        resetAccessToken: (access_token) => {
          localStorage.setItem("flyspace_jwt", access_token);
          window.location = "/";
        },
        getLoggedUser: () => {
          return state.user;
        },
        onLoggedOut: () => {
          setState({
            user: null, 
            isLoggedIn: false, 
            isLoading: false
          });
          localStorage.removeItem("flyspace_jwt");
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
