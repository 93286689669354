import React, { useContext, useEffect, useState } from 'react';

import {
  Container,
  Avatar,
  Typography,
  CircularProgress,
  Stack,
  Box,
  Tab,
  Tabs,
  useTheme,
  Card,
} from '@mui/material';

import Page from '../components/Page';
import FlownHoursStats from 'src/components/Stats';
import { DashboardManifest } from 'src/components/DashboardManifest';
import { AuthContext } from 'src/Auth';
import { useParams } from 'react-router-dom';
import UserContextProvider, { UserContext } from './User';
import { TrainingsList } from './TrainingsPage';
import Syllabus from 'src/components/Syllabus';
import Logbook from 'src/components/Logbook';
import Briefing from 'src/components/Briefing';
import Documents from 'src/components/Documents';
import Payments from 'src/components/Payments';
import { PaymentBalance } from './student/StudentsPaymentPage';
import { mToHours } from 'src/utils/formatTime';

export default function DashboardPage() {
  const { isLoggedIn, getUrl, user } = useContext(AuthContext); 
  const {user_id} = useParams();
  const userId = parseInt(user_id);

  return (
    <Page title="Flyspace | Dashboard">
        <Container>
            <Stack spacing={5}>
                <UserContextProvider userId={userId}>
                  <Dashboard></Dashboard>
                </UserContextProvider>
            </Stack>
        </Container>
   </Page>
  );
}

function Dashboard({userId}){
  const { user } = useContext(UserContext);
  const { getLoggedUser } = useContext(AuthContext);
  
  return(
      <Stack spacing={5}>
              { user === null && <CircularProgress></CircularProgress> }
              { user != null &&  user.id === getLoggedUser().id && <MyDashboard></MyDashboard> }
              { user != null &&  user.id !== getLoggedUser().id && <UserDashboard></UserDashboard> }
      </Stack>
  );
}


function MyDashboard(){
    const { user } = useContext(UserContext);

    return (
      <>
          <HoursBalance></HoursBalance>
          <FlownHoursStats></FlownHoursStats>
          {user.type === "STUDENT"  &&  <></>}
      </>
   )
}


const parseRoute = function(route){
  let routeObj = {
    tab: "stats", 
    training:null, 
    section: null
  };

  const routeEl = route.split("/");

  if(routeEl.length === 5){
    routeObj = {
      tab: routeEl[0].slice(1).toLowerCase(),
      training : parseInt(routeEl[routeEl.length-2]),
      section : routeEl[routeEl.length-1] + ""
    }
  }

  if(routeEl.length === 1 && routeEl[0].length > 0)
  {
    routeObj = {
      tab: routeEl[0].slice(1).toLowerCase(),
      training : null,
      section : null
    }
  }

  return routeObj;
}



function UserDashboard(){
  const {user} = useContext(UserContext);

  const [route, setRoute] = useState(parseRoute(window.location.hash));

  
  return (
  <>
    <DashboardUserMenu 
      selectedTab={route.tab}
      onChange={(tabKey) => { 
        setRoute(parseRoute(window.location.hash));
      }}
    ></DashboardUserMenu>

    { route && route.tab === "stats" && <FlownHoursStats></FlownHoursStats> }

    { route && route.tab === "trainings" && route.section === null && <TrainingsList hashRedirect={true} onRouteChanged={(route)=>{setRoute(parseRoute(route));}}></TrainingsList> }

    { route && route.tab === "trainings" && route.section !== null && route.section === "syllabus" && <Syllabus id={user.id} tid={route.training}></Syllabus>}

    { route && route.tab === "trainings" && route.section !== null && route.section === "briefing" && <Briefing id={user.id} trainingId={route.training}></Briefing>}

    { route && route.tab === "trainings" && route.section !== null && route.section === "logbook" && <Logbook id={user.id} editMode={false} filterType={"dual"} tid={route.training}></Logbook>}

    { route && route.tab === "documents" && <Documents id={user.id} editMode={false}></Documents>}

    { route && route.tab === "payments" && <Payments id={user.id} adminMode={false}></Payments>}
    
    { route && route.tab === "flights" && <Logbook id={user.id} editMode={false}></Logbook>}
 </>
);
}


function DashboardUserMenu({onChange, selectedTab}){
  const tabs = ["stats", "trainings", "documents", "payments", "flights", "agreements"];
  
  const { user } = useContext(UserContext);
  const [ tab, setTab ] = useState(tabs.indexOf(selectedTab));
 
  const switchTabs = (el, tab) => {
    let tabKey = el.currentTarget.name;
    
    if (window.location.hash !== `#${tabKey}`)
      window.location.hash = `#${tabKey}`

    selectTab(tabKey);
  }

  const selectTab = (tabKey) => {
    setTab(tabs.indexOf(tabKey));
    onChange(tabKey);
  }

  return (
  <>
    <Stack alignItems={'center'}>
      <Avatar src={user.picture} style={{width:100, height:100,  border: '3px solid lightgray', marginBottom:10}}></Avatar>
      <Typography variant='h4' style={{marginBottom:20}}>{user.name}</Typography>
      <Box sx={{ maxWidth: { xs: 320, sm: 480 }}}>
        <Tabs 
          value={tab} 
          scrollButtons={true}
          variant="scrollable"
          onChange={switchTabs}
          aria-label=""
        >
          <Tab name={"stats"} label={<>Stats</>} onClick={switchTabs}/>
          <Tab name={"trainings"} label={<>Trainings</>} onClick={switchTabs}/>
          <Tab name={"documents"} label={<>Documents</>} onClick={switchTabs}/>
          <Tab name={"payments"} label={<>Payments</>} onClick={switchTabs}/>
          <Tab name={"flights"} label={<>Flights</>} onClick={switchTabs}/>
        </Tabs>
      </Box>
    </Stack>
  </>)
}


export function HoursBalance()
{
  
  const { getUrl,user } = useContext(AuthContext);
  const [ balance, setBalance] = useState(null);
  
  useEffect(() => {
    fetch(getUrl('balance', "payments"))
      .then(res => res.json())
      .then(result => setBalance(result.value))
      .catch(error => {});
  }, []);
  
  if(user.admin == 1)
    return null

  return ( 
    <Stack direction="row" spacing={2} sx={{overflow: "auto"}}>
        {
          balance?.map(aircraftBalance => {
            return (
              <HoursCard width={"50%"} key={1}
                title={aircraftBalance.aircraft.identification} 
                subtitle={ 
                    <>
                      <Typography variant='body2'>{"Total flown hours: " + mToHours(aircraftBalance.total_hours*60)}</Typography>
                      <Typography variant='body2'>{"Total flown hours: " + mToHours(aircraftBalance.total_hours*60)}</Typography>
                    </>
                } 

                amount={ "Balance: "  + mToHours(aircraftBalance.total_paid_hours*60 - aircraftBalance.total_payable_hours*60) } 
              />
            )
          })
        }
    </Stack>
  ); 
}

function HoursCard({icon,title, subtitle, amount, width}) {
  const theme = useTheme();

  const style = {
    borderWidth: "1px",
    borderColor: theme.palette.grey["300"],
    borderStyle: "solid",
    width:"33%",
    minWidth:'250px',
    padding:10,
    boxShadow: "none", 
    padding:2, 
    overflow:"scroll"
  }

  return (
    <Card sx={{ ...style, width:(width ? width: '33%') }}>
      <Stack direction="row">
          <Stack direction="column">
            <Typography variant="body1" fontWeight="bold">{title}</Typography>
            {
              subtitle && <Typography variant="subtitle">{subtitle}</Typography>
            }
            <Typography variant="h5"> 
              {amount}
            </Typography>
          </Stack>
      </Stack>
    </Card>
  );
}


